import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  CardFooter
} from "reactstrap";
import moment from "moment";
import Notifications from "../components/Notifications";
import { combineAddress } from "../../../functions/Delivery.js"
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { getDateString } from "functions/Utility";

import InvoiceBadge from "../components/InvoiceBadge.js";
import DeliveryBadge from "../components/DeliveryBadge";
import { getInventoryColor } from "functions/GoodInventory";
import { getInvoiceStatusColor } from "functions/Invoice";

//props:
//invoiceData
//setClickViewInvoiceButton
//onClickCard
//isSelectedCard
//isMutedCard : cannot edit
//cloneInvoice
//customerAction
//accessCustomerAction
//accessCloneInvoice

// styleSkipBottomSpaceBoo


// Good => good
function InvoiceCard(props) {

  async function cloneInvoiceConfirmation(invoiceToClone) {
    let alert = {
      type: "cloneInvoiceConfirmation",
      title: "Are you Sure?",
      text: t("Ready to clone an invoice."),
      dataToPass: invoiceToClone,
      callback: cloneInvoice,
    };
    Notifications(alert);
  }

  function cloneInvoice(invoiceToClone) {
    props.cloneInvoice(invoiceToClone);
  }

  const { t, i18n } = useTranslation();
  const activeLang = i18n.language;

  const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;
  const accessCustomerAction = props.accessCustomerAction;
  const accessCloneInvoice = props.accessCloneInvoice;

  const styleSkipBottomSpaceBoo = props.styleSkipBottomSpaceBoo;

  const [invoice, setInvoice] = React.useState({});
  const [showFullCard, setShowFullCard] = React.useState(props.showFullCard ? props.showFullCard : true);// default tshow full card
  const [whiteText, setWhiteText] = React.useState("");

  React.useEffect(() => {
    let invoiceDataFromParent = props.invoiceData;
    if ((invoiceDataFromParent.InvoiceSubitems) && Array.isArray(invoiceDataFromParent.InvoiceSubitems) && invoiceDataFromParent.InvoiceSubitems.length >= 1) {
      invoiceDataFromParent.InvoiceSubitems.forEach(element => {
        if (element.Good) {
          element.good = element.Good;
        }
      });
    }
    setInvoice(invoiceDataFromParent);
  }, [props.invoiceData]);

  React.useEffect(() => {
    setShowFullCard(props.showFullCard);
  }, [props.showFullCard]);

  React.useEffect(() => {
    setWhiteText(" " + (props.enableCardBgColor ? "cardWhiteText" : ""));
  }, [props.enableCardBgColor]);

  return invoice ? (
    <Card
      className={styleSkipBottomSpaceBoo ? "mb-0" : ""} //naturally will have mb-4
      style={{ cursor: "pointer" }}
    >
      <CardHeader
        className={isSelectedCard ? "bg-gradient-success" : (props.enableCardBgColor ? props.cardBgColor : "")}>
        <div className={"d-flex justify-content-between" + whiteText}>
          <div className="ml--1">
            <Button
              className="mb-1"
              color="primary"
              size="sm"
              onClick={() => { setShowFullCard(!showFullCard) }}>
              <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
            </Button>
            {props.isMutedCard &&
              <Badge color="danger" className="statusBadge">
                {t("Not editable")}
              </Badge>
            }
            <InvoiceBadge
              invoiceData={invoice}
              badgeType="invoiceStatus"
              badgeClassName="statusBadge"
            />
            <InvoiceBadge
              invoiceData={invoice}
              badgeType="deliveryCount"
              badgeClassName="statusBadge"
            />
            <InvoiceBadge
              invoiceData={invoice}
              badgeType="paymentCount"
              badgeClassName="statusBadge"
            />
          </div>
          <div className="cardIconButton">
            {accessCustomerAction &&
              <>
                <i className="ni ni-single-02"
                  id="tooltip443412013"
                  onClick={(e) => {
                    props.customerAction(invoice?.Customer);
                  }} />
                <UncontrolledTooltip
                  delay={1}
                  target="tooltip443412013">
                  {t("Customer")}
                </UncontrolledTooltip>
                <span>&nbsp;&nbsp;&nbsp;</span>
              </>
            }
            {accessCloneInvoice &&
              <>
                <i className="ni ni-single-copy-04"
                  id="tooltip443412016"
                  onClick={(e) => {
                    cloneInvoiceConfirmation(invoice);
                  }} />
                <UncontrolledTooltip
                  delay={1}
                  target="tooltip443412016">
                  {t("Copy")}
                </UncontrolledTooltip>
              </>
            }
            <span>&nbsp;&nbsp;&nbsp;</span>
            <i className="ni ni-credit-card"
              id="tooltip443412012"
              onClick={(e) => {
                props.setClickViewInvoiceButton(invoice);
              }} />
            <UncontrolledTooltip
              delay={1}
              target="tooltip443412012">
              {t("invoice")}
            </UncontrolledTooltip>
          </div>
        </div>
        <Row
          onClick={(e) => {
            e.preventDefault();
            props.onClickCard(invoice);
          }}>
          <Col xs={12} >
            <div className={"tableRowTitle" + whiteText}>
              ${invoice.invoiceNetPrice}
            </div>

            <div >
              <InvoiceBadge
                invoiceData={invoice}
                badgeType="invoicePaidAmount"
                badgeClassName="statusBadge"
              />
              <InvoiceBadge
                invoiceData={invoice}
                badgeType="invoiceUnPaidAmount"
                badgeClassName="statusBadge"
              />
            </div>


            <div className={"font-weight-bold" + whiteText}>
              {`${getDateString(invoice.invoiceRecordDateTime, activeLang === "cn")}`}
            </div>
            <div className={"font-weight-bold tableRowRemark" + whiteText}>
              {invoice.invoiceRemark}
            </div>
            <div className={"text-muted" + whiteText}>
              {`${invoice.invoiceID}`}
            </div>
          </Col>

        </Row>
        <Row
          className={whiteText}
          onClick={(e) => {
            e.preventDefault();
            props.onClickCard(invoice);
          }}>
          <Col>
            <div className="tableRowSecondTitle">
              {invoice?.Customer?.customerName === undefined
                ? "-"
                : invoice?.Customer.customerName}
            </div>
            <div className="font-weight-bold">
              {(invoice?.Customer && invoice?.Customer?.CustomerPhones && invoice?.Customer?.CustomerPhones.length >= 1)
                ? `${invoice?.Customer?.CustomerPhones[0]?.phoneUserName}:${invoice?.Customer?.CustomerPhones[0]?.phoneNumber}`
                : t("No contact")}
            </div>
          </Col>
        </Row>
      </CardHeader>

      {showFullCard &&
        <CardBody
          className={isSelectedCard ? "bg-gradient-success" : (props.enableCardBgColor ? props.cardBgColor : "")}>

          {(invoice?.InvoiceSubitems) && (Object.values(invoice?.InvoiceSubitems).length > 0) &&
            <Row
              className={whiteText}>
              <Col onClick={(e) => {
                e.preventDefault();
                props.onClickCard(invoice);
              }}  >
                {invoice?.InvoiceSubitems?.map((invoiceSubitem, index) => (

                  <div key={index}>
                    <span>
                      <Badge
                        color={"warning"}
                        className="statusBadge"
                      >
                        {invoiceSubitem?.quantity}{invoiceSubitem?.good?.goodUnit}{invoiceSubitem?.good?.goodNameShortForm}
                      </Badge>
                    </span>
                    <span>
                      &nbsp;
                    </span>
                    <Badge
                      color={"default"}
                      className="statusBadge"
                    >
                      {`$${invoiceSubitem?.unitPrice * invoiceSubitem?.quantity}`}
                    </Badge>
                    <span>
                      &nbsp;
                    </span>
                    <Badge
                      color={"info"}
                      className="statusBadge"
                    >
                      {`$${invoiceSubitem?.unitPrice} / ${invoiceSubitem?.good?.goodUnit}`}
                    </Badge>


                  </div>

                ))}

              </Col>
            </Row>
          }
          {(invoice?.InvoiceSubitems) && (Object.values(invoice?.InvoiceSubitems).length <= 0) &&
            <Row
              className={whiteText}>
              <Col onClick={(e) => {
                e.preventDefault();
                props.onClickCard(invoice);
              }}  >
                <div className="tableRowSecondTitle">
                  <span>{t("Empty Invoice Items!")}</span>
                </div>


              </Col>
            </Row>
          }


        </CardBody>
      }

      {showFullCard && (invoice?.DeliveryRecords) && (Object.values(invoice?.DeliveryRecords).length > 0) &&
        <CardFooter
          className={isSelectedCard ? "bg-gradient-success" : (props.enableCardBgColor ? props.cardBgColor : "")}>
          <Row
            className={whiteText}>
            <Col onClick={(e) => {
              e.preventDefault();
              props.onClickCard(invoice);
            }}  >
              {invoice?.DeliveryRecords?.map((deliveryRecordItem, index) => (
                <div key={index}>
                  <div key={index}>
                    {combineAddress(deliveryRecordItem?.DeliveryAddress)}
                  </div>
                  <div>


                    <DeliveryBadge
                      deliveryRecordData={deliveryRecordItem}
                      badgeType="deliveryStatus"
                      badgeClassName="statusBadgeSmall"
                    />
                    <DeliveryBadge
                      deliveryRecordData={deliveryRecordItem}
                      badgeType="carID"
                      badgeClassName="statusBadgeSmall"
                    />
                    <DeliveryBadge
                      deliveryRecordData={deliveryRecordItem}
                      badgeType="driverID"
                      badgeClassName="statusBadgeSmall"
                    />



                  </div>
                </div>
              ))}

            </Col>

          </Row>
        </CardFooter>
      }
    </Card>
  ) : (
    <Col xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <strong className="text-info h1"><Trans>No Data Found</Trans></strong>
          <h3 className="text-info"><Trans>Please Check Your Filter</Trans></h3>
        </CardBody>
      </Card>
    </Col>
  );
}

export default InvoiceCard;
