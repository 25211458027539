import React, { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { uuid4 } from "@sentry/utils";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import { isConstructorDeclaration } from "typescript";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";



//props
//deliverySubitemsTable
// [{localDeliverySubitemID: uuid(), good, ...}]

function DeliverySubitemTable(props) {


    const { t } = useTranslation();

    const [deliverySubitemsTable, setDeliverySubitemsTable] = React.useState([]);

    React.useEffect(() => {
        if (props.deliverySubitemsTable && Array.isArray(props.deliverySubitemsTable)) {
            let arrayToAdd = props.deliverySubitemsTable;
            let resultArray = arrayToAdd.map((element) => {
                return Object.assign({}, {
                    localDeliverySubitemID: element.localDeliverySubitemID,
                    good: element.good,
                    // goodID: element.goodID,
                    deliverySubitemRemark: element.deliverySubitemRemark,
                    deliverySubitemQuantity: element.deliverySubitemQuantity
                });
            })
            setDeliverySubitemsTable(resultArray);
        }
    }, [props.deliverySubitemsTable]);



    async function deleteSubitemConfirmation(id) {
        let alert = {
            type: "deleteConfirmation",
            elementID: id,
            callback: confirmedDeleteSubitem,
        };
        Notifications(alert);
    }

    const confirmedDeleteSubitem = (localDeliverySubitemID) => {
        props.deleteSubitem(localDeliverySubitemID);
    }


    return (
        <>
            {deliverySubitemsTable &&
                deliverySubitemsTable?.length > 0 ? (

                <Table

                    style={{ cursor: "pointer" }}
                    className="align-items-center table-flush"
                    responsive
                    striped
                >
                    <thead className="thead-light tableHiddenHeader">
                        <tr>
                            <th className="tableTitle">
                                {" "}
                                <span className="tableTitle">
                                    {t("Items")}
                                </span>
                            </th>

                            <th>
                                {" "}
                                <span className="tableTitle">
                                    {t("Action")}
                                </span>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {deliverySubitemsTable.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td className="pl-3 pr-1" onClick={() => {

                                        if (props.allowEditProduct) {
                                            props.selectSubitem(row);
                                        }
                                    }}>
                                        <div className="tableRowTitle" >
                                            {row.deliverySubitemQuantity}
                                            {row.good?.goodUnit}{""}
                                            {row?.good?.goodDescription}
                                        </div>
                                        <div className="tableRowRemark">
                                            {(row.deliverySubitemRemark && row.deliverySubitemRemark !== '') ? `#${row.deliverySubitemRemark}` : ''}
                                        </div>
                                    </td>
                                    <td className=" pl-1 pr-1 table-actions d-flex justify-content-center">

                                        {/* edit button */}
                                        {false && props.allowEditProduct &&
                                            <>
                                                <a
                                                    className="table-action"
                                                    id="tooltip209424781"
                                                    onClick={() => {
                                                        props.selectSubitem(row);
                                                    }}
                                                >
                                                    <i className="fas fa-edit fa-lg" />
                                                    <span>{getAccessibleTagForSafeMode('deliveryEditEditProduct')}</span>
                                                </a>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    target="tooltip209424781"
                                                >
                                                    {t("Edit product")}
                                                </UncontrolledTooltip>

                                            </>
                                        }

                                        {/* DELETE button */}
                                        {props.allowDeleteProduct &&
                                            <>
                                                <a
                                                    className="table-action table-action-delete"
                                                    id="tooltip12475020"
                                                    onClick={() => {
                                                        deleteSubitemConfirmation(row.localDeliverySubitemID);
                                                    }}
                                                >
                                                    <i className="fas fa-trash fa-lg" />
                                                    <span>{getAccessibleTagForSafeMode('deliveryEditDeleteProduct')}</span>
                                                </a>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    target="tooltip12475020"
                                                >
                                                    {t("Delete product")}
                                                </UncontrolledTooltip>
                                            </>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            ) : (
                <div className="text-center mt-4">
                    <strong className="h3">
                        {t("No Item Added")}!
                    </strong>
                </div>


            )}
        </>
    );
}

export default DeliverySubitemTable;
