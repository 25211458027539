import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  Input,
  Collapse,
  Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
// core components
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";
import { addDays } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";

import { getInvoiceStatusArray } from "functions/Invoice.js";
import { getInvoiceIsDeliveryArray } from "functions/Invoice.js";
import { isInvoiceDelivery } from "functions/Invoice.js";
//props

// props.initInvoiceData
// prpos.returnShowCard
// props.returnFilteredSortedInvoiceData
// props.createInvoice

function InvoiceSearchBar(props) {
  const { t } = useTranslation();

  const [refreshTable, setRefreshTable] = React.useState(0);

  const [initInvoiceData, setInitInvoiceData] = React.useState([]);
  const [filteredSortedInvoiceData, setFilteredSortedInvoiceData] = React.useState([]);


  // Filter
  const [useSortPrice, setUseSortPrice] = React.useState(false);
  const [useSortDate, setUseSortDate] = React.useState(false);
  const [useSortInvoiceStatus, setUseSortInvoiceStatus] = React.useState(false);
  const [sortPriceSmall, setSortPriceSmall] = React.useState(false);
  const [sortDateSmall, setSortDateSmall] = React.useState(true);
  const [sortInvoiceStatusSmall, setSortInvoiceStatusSmall] = React.useState(false);

  // For filter invoiceStatusArray
  // In a structure [{id:"paid",text:"paid",isChecked:true/false}]
  const [invoiceStatusChooseData, setInvoiceStatusChooseData] = React.useState([]);
  const [invoiceIsDeliveryChooseData, setInvoiceIsDeliveryChooseData] = React.useState([]);

  // GUI
  const [showFullCard, setShowFullCard] = React.useState(false);
  const [showSortingButton, setShowSortingButton] = React.useState(false);

  // For date search
  const [searchString, setSearchString] = React.useState("");
  const [useFilterStartDate, setUseFilterStartDate] = React.useState(true);
  const [useFilterEndDate, setUseFilterEndDate] = React.useState(true);
  const [filterStartDate, setFilterStartDate] = React.useState(new Date());
  const [filterEndDate, setFilterEndDate] = React.useState(new Date());

  // For data communication with the screen
  const [dataRangeStartDate, setDataRangeStartDate] = React.useState(new Date());
  const [dataRangeEndDate, setDataRangeEndDate] = React.useState(new Date());


  // For filter control show
  const [showFilterSearch, setShowFilterSearch] = React.useState(false);
  const [showFilterInvoiceDateTimeRange, setShowFilterInvoiceDateTimeRange] = React.useState(false);
  const [showFilterInvoiceStatusArray, setShowFilterInvoiceStatusArray] = React.useState(false);
  const [showFilterInvoiceIsDelivery, setShowFilterInvoiceIsDelivery] = React.useState(false);

  // For control
  const [expandFilter, setExpandFilter] = React.useState(false);

  React.useEffect(() => {
    const getInvoiceStatusArrayFromFunction = async () => {
      let invoiceStatusArray = getInvoiceStatusArray();
      // Add a value isChecked
      if (invoiceStatusArray && Object.values(invoiceStatusArray).length >= 1) {
        invoiceStatusArray.forEach((item, index) => {
          item.isChecked = true; // default a true
        })
        setInvoiceStatusChooseData(invoiceStatusArray);
      } else {
        setInvoiceStatusChooseData([]);
      }
    };

    const getInvoiceIsDeliveryFromFunction = () => {

      let invoiceIsDeliveryArray = getInvoiceIsDeliveryArray();
      // Add a value isChecked
      if (invoiceIsDeliveryArray && Object.values(invoiceIsDeliveryArray).length >= 1) {
        invoiceIsDeliveryArray.forEach((item, index) => {
          item.isChecked = true; // default a true
        })
        setInvoiceIsDeliveryChooseData(invoiceIsDeliveryArray);
      } else {
        setInvoiceIsDeliveryChooseData([]);
      }
    };

    // Only do once
    getInvoiceStatusArrayFromFunction();
    getInvoiceIsDeliveryFromFunction();


  }, []);


  // showFilterDriver , showFilterCarID ... etc. are multually exclusive
  // Process in same useEffect function
  React.useEffect(() => {
    const enableBgColor = showFilterInvoiceStatusArray || showFilterInvoiceDateTimeRange || showFilterInvoiceIsDelivery;
    props.changeCardColor(enableBgColor, showFilterInvoiceStatusArray, showFilterInvoiceDateTimeRange, showFilterInvoiceIsDelivery);
  }, [showFilterInvoiceStatusArray, showFilterInvoiceDateTimeRange, showFilterInvoiceIsDelivery]);


  React.useEffect(() => {
    // do an inital sort
    searchDataMain("", "");
  }, [initInvoiceData, refreshTable]);


  React.useEffect(() => {
    setInitInvoiceData(props.initInvoiceData);
  }, [props.initInvoiceData]);

  React.useEffect(() => {
    // this is the key return
    props.returnFilteredSortedInvoiceData(filteredSortedInvoiceData);
  }, [filteredSortedInvoiceData]);

  React.useEffect(() => {
    // this is the key return
    props.returnShowFullCard(showFullCard);
  }, [showFullCard]);


  React.useEffect(() => {
    if (!useFilterStartDate || !useFilterEndDate) {
      // any one off 
      // must trigger reload the initial data from the server
      // will ignore the date range in the server
      // The Screen will handle the logic
      props.refreshInitialData(filterStartDate, filterEndDate, useFilterStartDate, useFilterEndDate);
    }
    if (useFilterStartDate && useFilterEndDate) {
      if (filterStartDate > filterEndDate) {
        // error case
        return;
      }
      if (dataRangeStartDate > dataRangeEndDate) {
        // error case
        return;
      }
      // a logic to compare calendarDate and dataDate
      if (dataRangeStartDate <= filterStartDate && filterEndDate <= dataRangeEndDate) {
        // save, go on
        // just do not trigger the data fetching from server
        return;
      }
      if (dataRangeStartDate > filterStartDate || filterEndDate > dataRangeEndDate) {
        // extend the data date range 
        // The Screen will handle the logic
        props.refreshInitialData(filterStartDate, filterEndDate, useFilterStartDate, useFilterEndDate);
      }
    }
  }, [filterStartDate, filterEndDate, useFilterStartDate, useFilterEndDate]);


  React.useEffect(() => {
    setDataRangeStartDate(props.dataRangeStartDate);
    setDataRangeEndDate(props.dataRangeEndDate);
  }, [props.dataRangeStartDate, props.dataRangeEndDate]);


  const countInvoiceStatusOn = () => {
    let counter = 0;
    if (invoiceStatusChooseData && invoiceStatusChooseData.length >= 1 && Object.values(invoiceStatusChooseData).length >= 1) {
      invoiceStatusChooseData.forEach((item) => {
        if (item.isChecked) {
          counter++;
        }
      })
    }
    return counter;
  }


  const countInvoiceIsDeliveryOn = () => {
    let counter = 0;
    if (invoiceIsDeliveryChooseData && invoiceIsDeliveryChooseData.length >= 1 && Object.values(invoiceIsDeliveryChooseData).length >= 1) {
      invoiceIsDeliveryChooseData.forEach((item) => {
        if (item.isChecked) {
          counter++;
        }
      })
    }
    return counter;
  }

  const closeAllFilterControl = () => {
    setShowFilterSearch(false);
    setShowFilterInvoiceDateTimeRange(false);
    setShowFilterInvoiceStatusArray(false);
    setShowFilterInvoiceIsDelivery(false);
  }


  const resetFilter = () => {
    setSearchString("");
    setFilterStartDate(new Date());
    setFilterEndDate(new Date());

    resetAllUseSort();
    resetAllSortOrder();
    try {
      let tempData_4 = invoiceStatusChooseData;
      tempData_4.forEach(item => item.isChecked = true);
      setInvoiceStatusChooseData([...tempData_4]);
    } catch (error) {
    }

    try {
      let tempData_5 = invoiceIsDeliveryChooseData;
      tempData_5.forEach(item => item.isChecked = true);
      setInvoiceIsDeliveryChooseData([...tempData_5]);
    } catch (error) {
    }

    setRefreshTable(refreshTable + 1);
  };

  const filterFuncInvoiceStatusArray = (singleInvoiceItem) => {
    try {
      // Cross check with invoiceStatusArray
      return invoiceStatusChooseData.findIndex((item) => { return (item.id === singleInvoiceItem?.invoiceStatus) && (item.isChecked) }) != -1;
    } catch (error) {
      return true;
    }
  };

  const filterFuncInvoiceIsDelivery = (singleInvoiceItem) => {
    try {
      // Cross check with invoiceStatusArray
      return invoiceIsDeliveryChooseData.findIndex((item) => {
        return (
          ((item.id === "Delivery") && (item.isChecked) && (isInvoiceDelivery(singleInvoiceItem))) ||
          ((item.id === "NonDelivery") && (item.isChecked) && !(isInvoiceDelivery(singleInvoiceItem)))
        )
      }) != -1;
    } catch (error) {
      return true;
    }
  };

  const searchDataMain = (searchType, searchValue, searchValue2 = null) => {
    const value = searchValue;
    const value2 = searchValue2;
    let res = [];
    let searchString_ = searchString;
    let searchStartDate_ = filterStartDate;
    let searchEndDate_ = filterEndDate;


    if (searchType === "search") {
      setSearchString(value);
      searchString_ = value;
    } else if (searchType === "startDate") {
      setFilterStartDate(value);
      searchStartDate_ = value;
    } else if (searchType === "endDate") {
      setFilterEndDate(value);
      searchEndDate_ = value;
    } else if (searchType === "period") {
      setFilterStartDate(value);
      searchStartDate_ = value;
      setFilterEndDate(value2);
      searchEndDate_ = value2;
    }

    // This function is filtering string of invoice
    const filterFuncString = (data) => {
      if (searchString_ !== "") {
        return (
          data.invoiceID.includes(searchString_) ||
          data.invoiceRemark.includes(searchString_) ||
          data?.Customer?.customerName.includes(searchString_) ||
          data?.Customer?.CustomerPhones[0]?.phoneNumber.includes(searchString_) ||
          filterProcedureDeliveryRecordList(data.DeliveryRecords)
        );
      } else {
        return true;
      }
    };


    // This function is filtering string of delivery record item
    // return a true or false
    // true: keyword hitted or no delivery record
    // false: there is some delivery record and keyword is not hitted
    const filterProcedureDeliveryRecordList = (deliveryRecordList) => {
      if (searchString_ === "") {
        return true;
      }
      if (deliveryRecordList && Array.isArray(deliveryRecordList) && deliveryRecordList.length >= 1) {
        let resAfterFilter;
        resAfterFilter = deliveryRecordList.filter(filterFuncStringDeliveryRecord);
        if (resAfterFilter && Array.isArray(resAfterFilter) && resAfterFilter.length >= 1) {
          // there is keywords hitted
          return true;
        } else {
          // No keyword hitted
          return false;
        }
      } else {
        // dont need to do filter
        return true;
      }
    };
    // This function is filtering string of single delivery record item
    const filterFuncStringDeliveryRecord = (deliveryRecordItem) => {
      if (searchString_ !== "") {
        const address =
          deliveryRecordItem?.DeliveryAddress?.addressDetailStreet +
          ", " +
          deliveryRecordItem?.DeliveryAddress?.addressDetailBuilding +
          ", " +
          deliveryRecordItem?.DeliveryAddress?.addressDetailStreet +
          ", " +
          deliveryRecordItem?.DeliveryAddress?.addressDetailDistrict +
          ", " +
          deliveryRecordItem?.DeliveryAddress?.addressDetailMainDistrict +
          ", " +
          deliveryRecordItem?.DeliveryAddress?.addressDetailCity;
        return (
          deliveryRecordItem.invoiceID.includes(searchString_) ||
          deliveryRecordItem?.Invoice?.Customer?.customerName.includes(searchString_) ||
          address.includes(searchString_) ||
          deliveryRecordItem.driverID.includes(searchString_) ||
          deliveryRecordItem.carID.includes(searchString_)
        );
      } else {
        return true;
      }
    };
    const filterFuncStartDate = (data) => {
      if (searchStartDate_ !== "") {
        const extDate = new Date(data.invoiceRecordDateTime);
        const filterDate = new Date(searchStartDate_);
        filterDate.setHours(0, 0, 0);
        return extDate >= filterDate;
      } else {
        return true;
      }
    };
    const filterFuncEndDate = (data) => {
      if (searchEndDate_ !== "") {
        const extDate = new Date(data.invoiceRecordDateTime);
        const filterDate = new Date(searchEndDate_);
        filterDate.setHours(23, 59, 59);
        return extDate <= filterDate;
      } else {
        return true;
      }
    };

    // Prevent null data
    if (!initInvoiceData || !Array.isArray(initInvoiceData) || initInvoiceData.length <= 0) {
      return;
    }

    res = initInvoiceData.filter(filterFuncString);

    if (useFilterStartDate) {
      res = res.filter(filterFuncStartDate);
    }
    if (useFilterEndDate) {
      res = res.filter(filterFuncEndDate);
    }

    res = res.filter(filterFuncInvoiceIsDelivery);

    res = res.filter(filterFuncInvoiceStatusArray);

    if (useSortPrice) {
      res = sortPriceWithOutput(res);
    } else if (useSortDate) {
      res = sortDateWithOutput(res);
    } else if (useSortInvoiceStatus) {
      res = sortInvoiceStatusWithOutput(res);
    }

    setFilteredSortedInvoiceData(res);
  };

  const sortPrice = (refreshBoo) => {
    resetAllUseSort();
    setUseSortPrice(true);
    setSortPriceSmall(!sortPriceSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  const sortDate = (refreshBoo) => {
    resetAllUseSort();
    setUseSortDate(true);
    setSortDateSmall(!sortDateSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  const sortInvoiceStatus = (refreshBoo) => {
    resetAllUseSort();
    setUseSortInvoiceStatus(true);
    setSortInvoiceStatusSmall(!sortInvoiceStatusSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  // take in data, sort and return
  const sortInvoiceStatusWithOutput = (dataToSort) => {
    if (!dataToSort) {
      return []; //return a empty array
    }
    let res = [];
    const smallBigControl = sortInvoiceStatusSmall ? 1 : -1;
    res = dataToSort.sort(
      (a, b) => {
        const nameA = a.invoiceStatus.toUpperCase(); // ignore upper and lowercase
        const nameB = b.invoiceStatus.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1 * smallBigControl;
        }
        if (nameA > nameB) {
          return 1 * smallBigControl;
        }
        // names must be equal
        return 0;
      }
    );
    return res;
  };

  const resetAllUseSort = () => {
    setUseSortPrice(false);
    setUseSortDate(false);
    setUseSortInvoiceStatus(false);
  }

  const resetAllSortOrder = () => {
    setSortDateSmall(false);
    setSortPriceSmall(false);
    setSortInvoiceStatusSmall(false);
  }

  // take in data, sort and return
  const sortDateWithOutput = (invoiceDataToSort) => {
    if (!invoiceDataToSort) {
      return []; //return a empty array
    }

    // setSortPriceSmall(true);
    let res = [];
    if (!sortDateSmall) {
      res = invoiceDataToSort.sort(
        (a, b) => {
          return new Date(a.invoiceRecordDateTime) - new Date(b.invoiceRecordDateTime)
        }
      );
    } else {
      res = invoiceDataToSort.sort(
        (a, b) => {
          return new Date(b.invoiceRecordDateTime) - new Date(a.invoiceRecordDateTime)
        });
    }
    return res;
  };

  // take in data, sort and return
  const sortPriceWithOutput = (invoiceDataToSort) => {
    if (!invoiceDataToSort) {
      return []; //return a empty array
    }

    //setSortDateSmall(true);
    let res = [];
    if (!sortPriceSmall) {
      res = invoiceDataToSort.sort(
        (a, b) =>
          a.invoiceNetPrice - b.invoiceNetPrice
      );
    } else {
      res = invoiceDataToSort.sort(
        (a, b) =>
          b.invoiceNetPrice - a.invoiceNetPrice
      );
    }

    return res;
  };

  return (
    <Card className="mb--2">
      <CardHeader
        role="tab"
        aria-expanded={expandFilter}>
        <Row>
          <Col className="col-auto mt-1 pr-0">
            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterSearch && expandFilter) {
                  setShowFilterSearch(!showFilterSearch);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterSearch && !expandFilter) {
                  setShowFilterSearch(!showFilterSearch);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterSearch && expandFilter) {
                  //Close the other

                  closeAllFilterControl();
                  setShowFilterSearch(true);
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterSearch ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterSearch ? "up" : "down"}`} />{" "}{t("Search")}
              {searchString && searchString !== "" && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
            </Button>

            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterInvoiceDateTimeRange && expandFilter) {
                  setShowFilterInvoiceDateTimeRange(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceDateTimeRange && !expandFilter) {
                  setShowFilterInvoiceDateTimeRange(true);
                  sortDate(true); // auto follow a sort
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceDateTimeRange && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterInvoiceDateTimeRange(true);
                  sortDate(true); // auto follow a sort
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterInvoiceDateTimeRange ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && setShowFilterInvoiceDateTimeRange ? "up" : "down"}`} />{"  "}{t("Date")}
              {(useFilterStartDate || useFilterEndDate) && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
            </Button>


            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterInvoiceStatusArray && expandFilter) {
                  setShowFilterInvoiceStatusArray(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceStatusArray && !expandFilter) {
                  setShowFilterInvoiceStatusArray(true);
                  sortInvoiceStatus(true); // auto follow a sort
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceStatusArray && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterInvoiceStatusArray(true);
                  sortInvoiceStatus(true); // auto follow a sort
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterInvoiceStatusArray ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterInvoiceStatusArray ? "up" : "down"}`} />{t("Invoice")}
              {/* {(countInvoiceStatusOn() != 0) || " "} */}
              {countInvoiceStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countInvoiceStatusOn()}</Badge>}

            </Button>


            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterInvoiceIsDelivery && expandFilter) {
                  setShowFilterInvoiceIsDelivery(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceIsDelivery && !expandFilter) {
                  setShowFilterInvoiceIsDelivery(true);
                  sortInvoiceStatus(true); // auto follow a sort
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceIsDelivery && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterInvoiceIsDelivery(true);
                  sortInvoiceStatus(true); // auto follow a sort
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterInvoiceIsDelivery ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterInvoiceIsDelivery ? "up" : "down"}`} />{t("Delivery")}
              {countInvoiceIsDeliveryOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countInvoiceIsDeliveryOn()}</Badge>}

            </Button>
          </Col>

          <Col className="col-auto mt-1 pr-0">
            <Button
              onClick={() => { setShowFullCard(!showFullCard) }}
              className="mt-1"
              color={"info"}>
              <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
            </Button>


            <Button
              className="mt-1"
              onClick={() => { setShowSortingButton(!showSortingButton) }}
              color="info">
              <i className={`fas fa-sort-numeric-${showSortingButton ? "up" : "down"}`} />{t("Sort")}
            </Button>

            <Button
              className="mt-1"
              color="danger"
              id="tooltip443412081"
              onClick={(e) => {
                e.preventDefault();
                resetFilter();
              }}
              size="md"
            >
              <span className="btn-inner--text">
                {t("Reset Filter")}
              </span>
            </Button>
            <UncontrolledTooltip delay={1} target="tooltip443412081">
              {t("Reset Filter")}
            </UncontrolledTooltip>

          </Col>
          <Col className="col-auto mt-1">
            <Button
              className="mt-1"
              color="success"
              id="tooltip443412089"
              onClick={(e) => {
                props.createInvoice();
              }}
              size="md"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">{t("New invoice")}</span>
            </Button>
            <UncontrolledTooltip delay={1} target="tooltip443412089">
              {t("Create")}
            </UncontrolledTooltip>

          </Col>
        </Row>
        {showSortingButton &&
          <Row>
            <Col className="col-auto mt-1 pr-0" >
              <Button
                className="mt-1"
                onClick={() => sortInvoiceStatus(true)}
                color={useSortInvoiceStatus ? "default" : "info"}>
                <i className={`fas fa-sort-numeric-${sortInvoiceStatusSmall ? "up" : "down"}`} />{t("Invoice")}
              </Button>

              <Button
                className="mt-1"
                onClick={() => sortPrice(true)}
                color={useSortPrice ? "default" : "info"}>
                <i className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"}`} />{t("Price")}
              </Button>

              <Button
                className="mt-1"
                onClick={() => sortDate(true)}
                color={useSortDate ? "default" : "info"}>
                <i className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"}`} />{t("Date")}
              </Button>


            </Col>
          </Row>
        }


      </CardHeader>
      <Collapse
        role="tabpanel"
        isOpen={expandFilter}
      >
        <CardBody>
          {showFilterSearch &&
            <Row className="mt-2">
              <Col
                className="mt-1"
                lg={12} md={12} xs={12}
              >
                <InputTypeTextForm
                  label={t("Search")}
                  labelFor="searchText"
                  inputName="searchText"
                  inputId="searchText"
                  inputPlaceholder={t("Search (Invoice number/Customer/Address/Driver/CarID)")}
                  value={searchString}
                  handleChange={(e) => {
                    e.preventDefault();
                    searchDataMain("search", e?.target?.value);
                  }}
                  readOnly={false}
                />

              </Col>
            </Row>
          }

          {showFilterInvoiceDateTimeRange &&
            <>
              <Row>
                <Col>
                  <InputDateGroupCombo
                    labelFor="invoiceRecordDateTime"
                    inputName="invoiceRecordDateTime"
                    inputId="invoiceRecordDateTime"
                    useStartDate={useFilterStartDate}
                    useEndDate={useFilterEndDate}
                    changeUseStartDate={(boo) => { 
                      setUseFilterStartDate(boo);
                       setRefreshTable(refreshTable + 1); }}
                    changeUseEndDate={(boo) => { setUseFilterEndDate(boo); setRefreshTable(refreshTable + 1); }}
                    startDate={filterStartDate}
                    endDate={filterEndDate}
                    changeStartDate={(date) => { 
                      searchDataMain("startDate", date); }}
                    changeEndDate={(date) => { searchDataMain("endDate", date); }}
                    changePeriod={(startDate, endDate) => {
                      searchDataMain("period", startDate, endDate);
                    }}
                  >
                  </InputDateGroupCombo>
                </Col>
              </Row>
            </>
          }

          {showFilterInvoiceStatusArray &&
            <Row className="mt-2 ml-1">
              {invoiceStatusChooseData.map((invoiceStatusItem, index) => (
                <FilterBadge
                  key={index}
                  textToShow={t(invoiceStatusItem.text)}
                  isChecked={invoiceStatusItem.isChecked}
                  setIsChecked={(isChecked) => {
                    let selectedItemIndex = invoiceStatusChooseData.findIndex((item) => { return item.id === invoiceStatusItem.id });
                    if (selectedItemIndex != -1) {
                      try {
                        invoiceStatusChooseData[selectedItemIndex].isChecked = isChecked;
                        setInvoiceStatusChooseData([...invoiceStatusChooseData]);
                      } catch (error) {
                      }
                    }
                    searchDataMain("", "");
                  }}
                />
              )
              )
              }
            </Row>
          }

          {showFilterInvoiceIsDelivery &&
            <Row className="mt-2 ml-1">
              {invoiceIsDeliveryChooseData.map((invoiceIsDeliveryItem, index) => (
                <FilterBadge
                  key={index}
                  textToShow={t(invoiceIsDeliveryItem.text)}
                  isChecked={invoiceIsDeliveryItem.isChecked}
                  setIsChecked={(isChecked) => {
                    let selectedItemIndex = invoiceIsDeliveryChooseData.findIndex((item) => { return item.id === invoiceIsDeliveryItem.id });
                    if (selectedItemIndex != -1) {
                      try {
                        invoiceIsDeliveryChooseData[selectedItemIndex].isChecked = isChecked;
                        setInvoiceIsDeliveryChooseData([...invoiceIsDeliveryChooseData]);
                      } catch (error) {
                      }
                    }
                    searchDataMain("", "");
                  }}
                />
              )
              )
              }
            </Row>
          }

        </CardBody>
      </Collapse>
    </Card>
  );
}

export default InvoiceSearchBar;
