import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm.js";
import Notifications from "../components/Notifications";
import {
  getGoodSelect2,
} from "../../../functions/Goods";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import SpinnerOverlay from "../components/SpinnerOverlay";
import GoodInventoryBadge from "../components/GoodInventoryBadge.js";
import GoodInventoryModalEdit from "../good/GoodInventoryModalEdit.js";

function InvoiceEditSubitemModal(props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshCounter, setRefreshCounter] = React.useState(0);


  //Initialize Data
  const [goodChooseList, setGoodChooseList] = React.useState([]);

  // For display 
  const [subItemPrice, setSubItemPrice] = React.useState(0);


  //Post Data
  const [subitemToEdit, setSubitemToEdit] = React.useState({
    localSubitemID: "",
    goodSelected: {},
    quantity: 0,
    unitPrice: 0,
    invoiceSubitemRemark: ""
  });

  const { localSubitemID, goodSelected, quantity, unitPrice, invoiceSubitemRemark } = subitemToEdit;


  //Other Display data
  const [selectedGoodInventoryList, setSelectedGoodInventoryList] = React.useState([]);

  const [selectedGoodInventoryData, setSelectedGoodInventoryData] = React.useState({});
  const [selectedStoreData, setSelectedStoreData] = React.useState({});

  // Modal 
  const [modalEditInventoryIsOpen, setModalEditInventoryIsOpen] = React.useState(false);

  //Initial Get Good Data List
  React.useEffect(() => {
    const initDataGood = async () => {
      let goodListToGet = await getGoodSelect2();
       setGoodChooseList(goodListToGet);
    };
    if (props.show) {
      initDataGood();
    }
  }, [props.show, refreshCounter]);


  //Initial Get Good Data List
  React.useEffect(() => {
    if (goodChooseList && Array.isArray(goodChooseList) && goodChooseList.length >= 1) {
      if (goodSelected && Object.values(goodSelected).length >= 1) {
        // just to re-select the good
        onChooseGood(goodSelected.goodID, false, true);
      }
    }
  }, [goodChooseList]);


  React.useEffect(() => {
    setSubItemPrice(quantity * unitPrice);
  }, [quantity, unitPrice]);


  //props.show means this form is triggered by parent form
  React.useEffect(() => {
    if (props.show) {
      if (Object.values(props.invoicesSubitemsData).length > 0) {
        setSubitemToEdit({
          localSubitemID: props.invoicesSubitemsData.localSubitemID,
          quantity: props.invoicesSubitemsData.quantity,
          unitPrice: props.invoicesSubitemsData.unitPrice,
          invoiceSubitemRemark: props.invoicesSubitemsData.invoiceSubitemRemark,
          goodSelected: props.invoicesSubitemsData.good
        });


      } else {
        setSubitemToEdit({
          localSubitemID: "",
          quantity: 0,
          unitPrice: 0,
          invoiceSubitemRemark: "",
          goodSelected: {}
        });

      }

      setSubItemPrice(props.invoicesSubitemsData.quantity * props.invoicesSubitemsData.unitPrice);
    }
  }, [props.show]);


  const onInputChange = (e) => {

    if (e.target.name == "" ) {

    } else {
      setSubitemToEdit({ ...subitemToEdit, [e.target.name]: e.target.value });
    }
  };


  const onChangeUnitPrice = (unitPriceToChange) => {

    if (isNaN(parseFloat(unitPriceToChange))) {
      // To improve user experience
      // Allow a empty string ''
      setSubitemToEdit({ ...subitemToEdit, unitPrice: ''});
    } else   {
      setSubitemToEdit({ ...subitemToEdit, unitPrice: parseFloat(unitPriceToChange) });
    }
  };

  const onChangeQuantity = (quantityToChange) => {

    if (isNaN(parseFloat(quantityToChange))) {
      // To improve user experience
      // Allow a empty string ''
      setSubitemToEdit({ ...subitemToEdit, quantity: '' });
    } else {
      setSubitemToEdit({ ...subitemToEdit, quantity: parseFloat(quantityToChange) });
    }
  };

  const onChooseGood = async (goodID, changePriceQuantity = true , changeInventory = true) => {
    if (goodID === "") {

    } else {
      let goodFromServer = (getGoodFromLocalList(goodID)) || [];
      if (goodFromServer) {
        if (changePriceQuantity) {
          setSubitemToEdit({ ...subitemToEdit, goodSelected: goodFromServer, unitPrice: parseFloat(goodFromServer.goodDefaultUnitPrice), quantity: parseFloat(goodFromServer.goodDefaultUnitQuantity) });
        }
        if (changeInventory) {
          onSetGoodInventoryList(goodFromServer.GoodInventories);
        }
      }
    }
  };


  const onSetGoodInventoryList = (goodInventoryList) => {
    if (goodInventoryList && Array.isArray(goodInventoryList) && goodInventoryList.length >= 0) {
      setSelectedGoodInventoryList(goodInventoryList);
    } else {
      setSelectedGoodInventoryList([]);
    }
  }

  const getGoodFromLocalList = (goodID) => {

    if (!goodChooseList || Object.values(goodChooseList).length <= 0) {
      return null;
    }
    let goodArrayToGet = goodChooseList.find((item) => item.id === goodID);
    if (!goodArrayToGet || Object.values(goodArrayToGet).length <= 0) {
      return null;
    }
    return goodArrayToGet.obj;
  }


  const updateSubitems = (e) => {
    // Data check 
    if (isNaN(parseFloat(quantity))) {
      Notifications({
        type: "dataCheck",
        message: t("Please input a quantity")
      });
      return;
    }

    if (isNaN(parseFloat(unitPrice))) {
      Notifications({
        type: "dataCheck",
        message: t("Please input a unitPrice")
      });
      return;
    }

    props.updateInvoicesSubitems(
      localSubitemID,
      quantity,
      unitPrice,
      invoiceSubitemRemark,
      goodSelected
    );
    // reset the form
    setSubitemToEdit({
      localSubitemID: "",
      quantity: 0,
      unitPrice: 0,
      invoiceSubitemRemark: "",
      goodSelected: {}
    });

    props.onHide();

  };



  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader >{props.title}</ModalHeader>
        <ModalBody className="mt--4">
          <Col>
            <Form>
              <InputTypeSelect2Form
                data={goodChooseList}
                placeholder={t("--Select Item--")}
                value={goodSelected?.goodID}
                nameSelect2="goodID"
                idSelect2="goodID"
                label={t("Item Name")}
                // This will return a e and get the ID by e.target.value
                handleOnChangeSelect={(e) => {
                  onChooseGood(e.target.value, true, true);
                }
                }
                disabled={props.disableGoodSelection}
              />
              <div className="mt-2 d-flex">
                {(selectedGoodInventoryList && Array.isArray(selectedGoodInventoryList) && selectedGoodInventoryList.length >= 1) &&
                  selectedGoodInventoryList.map((item, index) => {
                    return (
  
                      <GoodInventoryBadge
                        key={index}
                        goodInventoryData={item}
                        goodData={goodSelected}
                        badgeType=""
                        badgeClassName="statusBadge"
                        onClickBadge={(goodInventoryData, goodData) => {
                           setSelectedGoodInventoryData(goodInventoryData);
                           setSelectedStoreData(goodInventoryData.Store);
                          // setSelectedGoodData(goodData);
                           setModalEditInventoryIsOpen(true);
                        }}
                      />
                    )
                  })
                }
              </div>


              <InputTypeGroupTextForm
                type="number"
                append={goodSelected ? true : false}
                appendIcon={false}
                appendIconClass=""
                appendText={
                  goodSelected
                    ? "/" + goodSelected?.goodUnit
                    : ""
                }
                label={t("Price")}
                labelFor="unitPrice"
                inputName="unitPrice"
                inputId="unitPrice"
                inputPlaceholder={t("Price")}
                value={unitPrice}
                handleChange={(e) => onChangeUnitPrice(e.target.value)}
              //readOnly={true}
              />
              <InputTypeTextForm
                type="number"
                label={t("Quantity")}
                labelFor="quantity"
                inputName="quantity"
                inputId="quantity"
                inputPlaceholder={t("Quantity")}
                value={quantity}
                handleChange={(e) => onChangeQuantity(e.target.value)}
              />
              <InputTypeTextForm
                label={t("subtotal")}
                labelFor="subItemPrice"
                inputName="subItemPrice"
                inputId="subItemPrice"
                inputPlaceholder={t("subtotal")}
                value={subItemPrice}
                readOnly={true}
              //  handleChange={(e) => onInputChange(e)}
              />
              <InputTypeTextForm
                label={t("Invoice Item Remark")}
                labelFor="invoiceSubitemRemark"
                inputName="invoiceSubitemRemark"
                inputId="invoiceSubitemRemark"
                inputPlaceholder={t("Invoice Item Remark")}
                value={invoiceSubitemRemark}
                handleChange={(e) => onInputChange(e)}
              />
            </Form>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => updateSubitems(e)} type="button">
            {props.button}
          </Button>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>

      <GoodInventoryModalEdit
        show={modalEditInventoryIsOpen}
        onHide={() => setModalEditInventoryIsOpen(false)}
        title={t("Edit good inventory")}
        button={t("Update")}
        goodData={goodSelected}
        goodInventoryData={selectedGoodInventoryData}
        storeData={selectedStoreData}
        refreshTable={() => { setRefreshCounter(refreshCounter + 1) }}
      />
    </>
  );
}

export default InvoiceEditSubitemModal;
