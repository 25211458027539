import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Table,
  Button,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from "reactstrap";
import { uuid4 } from "@sentry/utils";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

// core components
import Dropzone from "dropzone";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm.js";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import DeliveryRecordAddProductModal from ".//DeliveryRecordAddProductModal";
import DeliveryRecordEditProductModal from "./DeliveryRecordEditProductModal";

import { getDriverSelect2 } from "functions/Driver";
import { getCarSelect2 } from "functions/Car";
import { storeDeliveryRecord, combineAddress } from "functions/Delivery";
import { addDeliveryAddress } from "functions/DeliveryAddress";
import Notifications from "../components/Notifications";
import moment from "moment";
import DeliverySubitemTable from "../components/DeliverySubitemTable";
import DropdownControl from "../components/DropdownControl";
import { isAccessible } from "functions/SystemUserAccess";
import DeliveryAddressSelectInput from "../components/DeliveryAddressSelectInput";
import SpinnerOverlay from "../components/SpinnerOverlay"; import {
  getRemainingDeliverySubitemsByInvoiceID
} from "functions/Delivery";
import renameKeys from "functions/RenameKey";
import { getDefaultDriverID } from "functions/Driver";
import { getDefaultCarID } from "functions/Car";
import AddressModalCreate from "../address/AddressModalCreate";
import SubmitButton from "../components/SubmitButton";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";
import DeliveryAddressBadge from "../components/DeliveryAddressBadge";
import AddressRelatedDeliveryRecord from "../address/AddressRelatedDeliveryRecord";
import { getDeliveryRecordsByAddressID } from "functions/Delivery.js";


Dropzone.autoDiscover = false;


function DeliveryRecordCreateModal(props) {
  const { t } = useTranslation();


  const [isLoading, setIsLoading] = React.useState(false);

  //Initialize Get Set
  const today = new Date();
  const [driverChooseSelect2Data, setDriverChooseSelect2Data] = React.useState([]);
  const [carChooseSelect2Data, setCarChooseSelect2Data] = React.useState([]);

  // modal control
  const [modalAddProduct, setModalAddProduct] = React.useState(false);
  const [modalEditProduct, setModalEditProduct] = React.useState(false);
  const [showDelivery, setShowDelivery] = React.useState(true);
  const [showProduct, setShowProduct] = React.useState(true);
  const [showAll, setShowAll] = React.useState(true);
  const [modalCreateDeliveryAddressIsOpen, setModalCreateDeliveryAddressIsOpen] = React.useState(false);
  const [additionalAddressDataArray, setAdditionalAddressDataArray] = React.useState([]);

  const [modalAddressRelatedDeliveryRecordIsOpen, setModalAddressRelatedDeliveryRecordIsOpen] = React.useState(false);
  

  //Form operation
  const [invoiceData, setInvoiceData] = React.useState(props.invoiceData);
  const [selectedSubitem, setSelectedSubitem] = React.useState([]);
  const [deleteSelectedDeliverySubitems, setDeleteSelectedDeliverySubitems] = React.useState("");
  const [preSelectAddressID, setPreSelectAddressID] = React.useState("");


  //Post Data

  const [driverID, setDriverID] = React.useState(getDefaultDriverID(driverChooseSelect2Data));
  const [carID, setCarID] = React.useState(getDefaultCarID(carChooseSelect2Data));
  const [proposedDateTime, setproposedDateTime] = React.useState(today);
  const [selectedAddressID, setSelectedAddressID] = React.useState("");
  const [selectedAddressData, setSelectedAddressData] = React.useState({});


  // [{localDeliverySubitemID: uuid(), good, ...}]
  const [deliverySubitemList, setDeliverySubitemsTable] = React.useState([]);

  const updateSelectedAddressData = async (deliveryAddressData) => {
    let relatedDeliveryRecordList = [];
    if (deliveryAddressData && deliveryAddressData.deliveryAddressID) {
      relatedDeliveryRecordList = await getDeliveryRecordsByAddressID(deliveryAddressData.deliveryAddressID);
    }
    deliveryAddressData.relatedDeliveryRecordList = relatedDeliveryRecordList;
    setSelectedAddressData(deliveryAddressData);
  }




  const getRemainingGoodListSetToSubItemTable = async () => {
    let getGoodList = await getRemainingDeliverySubitemsByInvoiceID(props.invoiceData.invoiceID);
    //Transform the table from DB to the local subitem table
    getGoodList.forEach((obj) => {
      renameKeys(obj, "remainingQuantity", "deliverySubitemQuantity");
      renameKeys(obj, "remark", "deliverySubitemRemark");
      renameKeys(obj, "Good", "good");
    });
    replaceItemArrayToTable(getGoodList);
  };

  //Initialize Effect
  React.useEffect(() => {
    if (props.show) {
      // here get all the data from DB
      const getDriver = async () => {
        let driver = await getDriverSelect2();
        setDriverChooseSelect2Data(driver);
        //  setDriverID(driver[0]?.driverID);
      };
      const getCar = async () => {
        let car = await getCarSelect2();
        setCarChooseSelect2Data(car);
        //  setCarID(car[0]?.carID);
      };


      getDriver();
      getCar();
      setInvoiceData(props.invoiceData);

      // From other page or component which carries a state with the delivery record to clone
      // make sure deliveryRecordToClone is valid and not empty
      if (props.deliveryRecordToClone !== undefined && props.deliveryRecordToClone !== null && Object.values(props.deliveryRecordToClone).length >= 1) {
        let DRToClone = props.deliveryRecordToClone;

        setPreSelectAddressID(DRToClone.deliveryAddressID);
        setproposedDateTime(new Date(DRToClone.proposedDateTime));
        setDriverID(DRToClone.driverID);
        setCarID(DRToClone.carID);
        replaceItemArrayToTable(DRToClone.DeliveryRecordSubitems);
      } else {
        if (Object.values(deliverySubitemList).length == 0) {
          getRemainingGoodListSetToSubItemTable();
        }
      }
    } else {
      // Clear input 
      // Do not clear the input when quitting form
      // Clear when 
    }
  }, [props.show]);


  const clearForm = () => {
    //Form operation
    setInvoiceData({});
    setSelectedSubitem({});
    setDeleteSelectedDeliverySubitems("");
    setPreSelectAddressID("");
    setAdditionalAddressDataArray([]);

    //Post Data
    setDriverID(getDefaultDriverID(driverChooseSelect2Data));
    setCarID(getDefaultCarID(carChooseSelect2Data));
    setproposedDateTime(today);
    setSelectedAddressID("");
    setSelectedAddressData({});


    // reset to original
    getRemainingGoodListSetToSubItemTable();
  };

  React.useEffect(() => {
    setShowDelivery(showAll);
    setShowProduct(showAll);
  }, [showAll]);



  React.useEffect(() => {
    if (preSelectAddressID && preSelectAddressID !== "") {
      setSelectedAddressID(preSelectAddressID);
    }
  }, [preSelectAddressID]);




  function replaceItemArrayToTable(dataArray) {

    if (dataArray && Array.isArray(dataArray) && dataArray.length >= 1) {
      let arrayToTable = dataArray.map((element) => {
        return Object.assign({}, {
          localDeliverySubitemID: uuid4(),
          good: element.good ? element.good : element.Good, // if the data come from dB, the good is "Good"
          deliverySubitemRemark: element.deliverySubitemRemark,
          deliverySubitemQuantity: element.deliverySubitemQuantity || 0
        });
      });
      setDeliverySubitemsTable([...arrayToTable]);
    }
  }


  function addItemArrayToTable(dataArray) {
    if (dataArray && Array.isArray(dataArray) && dataArray.length >= 1) {
      let arrayToTable = dataArray.map((element) => {
        return Object.assign({}, {
          localDeliverySubitemID: uuid4(),
          good: element.good ? element.good : element.Good, // if the data come from dB, the good is "Good"
          // goodID: element.goodID,
          deliverySubitemRemark: element.deliverySubitemRemark,
          deliverySubitemQuantity: element.quantity ? element.quantity : element.deliverySubitemQuantity
        });
      });
      setDeliverySubitemsTable([...deliverySubitemList, ...arrayToTable]);
    }
  }



  function editItemInTable(itemToEdit) {

    let arrData = deliverySubitemList.filter(
      (val) => val.localDeliverySubitemID !== itemToEdit.localDeliverySubitemID
    );

    let modObj = Object.assign({}, {
      localDeliverySubitemID: itemToEdit.localDeliverySubitemID,
      good: element.good ? element.good : element.Good, // if the data come from dB, the good is "Good"
      // goodID: element.goodID,
      deliverySubitemRemark: element.deliverySubitemRemark,
      deliverySubitemQuantity: element.quantity ? element.quantity : element.deliverySubitemQuantity
    });

    setDeliverySubitemsTable([...arrData, modObj]);
  }


  React.useEffect(() => {
    const deleteDeliveryRecordSubitems = () => {
      setDeliverySubitemsTable(
        deliverySubitemList.filter(
          (data) => data.localDeliverySubitemID !== deleteSelectedDeliverySubitems
        )
      );
      setDeleteSelectedDeliverySubitems("");
    };

    if (deleteSelectedDeliverySubitems !== "") {
      deleteDeliveryRecordSubitems();
    }
  }, [deleteSelectedDeliverySubitems]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // check carID
    if (carID === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a car")
      });
      return false;
    }

    // check driverID
    if (driverID === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a driver")
      });
      return false;
    }

    try {

      let deliverySubItemArrayToSubmit = [];
      if (deliverySubitemList) {
        for (let i = 0; i < deliverySubitemList.length; i += 1) {
          const obj = {
            goodID: deliverySubitemList[i].good.goodID,
            deliverySubitemQuantity: deliverySubitemList[i].deliverySubitemQuantity,
            deliverySubitemRemark: deliverySubitemList[i].deliverySubitemRemark,
          };
          deliverySubItemArrayToSubmit.push(obj);
        }
      }

      let breakBoo = false;
      try {
        deliverySubItemArrayToSubmit.every((item) => {
          if (!item.goodID || item.goodID === "") {
            Notifications({
              type: "dataCheck",
              message: t("Please choose a good")
            });
            breakBoo = true;
            return false;
          } else if (!item.deliverySubitemQuantity || isNaN(parseFloat(item.deliverySubitemQuantity))) {
            Notifications({
              type: "dataCheck",
              message: t("Please input a quantity")
            });
            breakBoo = true;
            return false;
          }
          return true;
        })
      } catch (error) {
        return false;
      }
      if (breakBoo) {
        return false;
      }


      let deliveryAddressID = selectedAddressID;

      if (deliveryAddressID !== undefined && deliveryAddressID !== "" && driverID !== "" && carID !== "") {
        let dataSubmit = {
          invoiceID: invoiceData?.invoiceID,
          proposedDateTime: proposedDateTime,
          deliveryStatus: "pending",
          driverID: driverID,
          deliveryAddressID: deliveryAddressID,
          deliveryRemark: "",
          carID: carID,
          deliveryRecordSubitems: deliverySubItemArrayToSubmit,
        };

        let deliveryID = await storeDeliveryRecord(dataSubmit);
        if (deliveryID && deliveryID !== "") {
          // Clear input when finish submit
          clearForm();
          props.onHide();
          return true;
        } else {
          return false;
        }


      } else {
        Notifications({ type: "dataCheck", message: t("All input must be filled!") });
        return false;
      }
    } catch (err) {
      Notifications({ type: "error", message: err.message });
      return false;
    }
  };

  return (
    <>

      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
          <div>
            <div>{t("Create Delivery")}</div>
            <div className="mt-1">
              <Badge color="primary" className="statusBadge">{invoiceData?.invoiceID}</Badge>
              <span>&nbsp;&nbsp;</span>
              <i
                className={`fa fa-caret-${showAll ? "up" : "down"} icon`}
                style={{ cursor: "pointer" }}
                onClick={() => setShowAll(!showAll)}
              />
            </div>
          </div>
        </ModalHeader>
        <Form>
          <ModalBody>




            {/* Product Information */}
            <Row>
              <Col sm="12" lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <Row className="d-flex align-items-center justify-content-between">
                        <Col
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                        >
                          <h1>{`${deliverySubitemList.length}`}{t("items")}</h1>
                        </Col>
                        <Col
                          className="text-right d-flex justify-content-end align-items-center"
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                        >
                          <div>

                            <Button
                              color="primary"
                              id="tooltip443412080"
                              onClick={() => setModalAddProduct(true)}
                              size="sm"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text">
                                {t("Add")}
                              </span>
                            </Button>

                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowProduct(!showProduct)}
                            className="icon d-flex justify-content-end mt-2"
                          >
                            <i
                              className={`fa fa-caret-${showProduct ? "up" : "down"
                                }`}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    {showProduct && (
                      <CardBody className="ml--3 mr--3 mt--4">
                        <span>{getAccessibleTagForSafeMode('deliveryCreateEditProduct')}</span>
                        <span>{getAccessibleTagForSafeMode('deliveryCreateDeleteProduct')}</span>
                        <DeliverySubitemTable
                          allowEditProduct={isAccessible('deliveryCreateEditProduct')}
                          allowDeleteProduct={isAccessible('deliveryCreateDeleteProduct')}
                          deliverySubitemsTable={deliverySubitemList} // 入去之前已經有 localDeliverySubitemID
                          selectSubitem={(deliverySubitemItem) => {
                            setSelectedSubitem(deliverySubitemItem);
                            setModalEditProduct(true);
                          }}
                          deleteSubitem={(subItemID) => {
                            setDeleteSelectedDeliverySubitems(subItemID);
                          }}
                        />
                      </CardBody>
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
            {/* End Product Information */}



            {/* Delivery Information */}
            <Row>
              <Col sm="12" lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center">
                      <h3 className="mb-0">{t("Delivery Information")}</h3>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowDelivery(!showDelivery)}
                        className="icon d-flex justify-content-end"
                      >
                        <i
                          className={`fa fa-caret-${showDelivery ? "up" : "down"
                            }`}
                        />
                      </div>
                    </CardHeader>
                    {showDelivery && (
                      <CardBody className="mb-2">
                        <Row>
                          <Col md={12} sm={12}>
                            <Row className="d-flex align-items-center mb-2">

                              <Col>
                                <DropdownControl
                                  disabled={false}
                                  defaultColor="primary"
                                  dropdownName={t("Car")}
                                  useTranslate={false}
                                  arrayIDTextFormat={carChooseSelect2Data}
                                  valueID={carID}
                                  onSelectValueID={(valueID) => {
                                    setCarID(valueID);
                                  }}
                                />

                                <DropdownControl
                                  disabled={false}
                                  defaultColor="primary"
                                  dropdownName={t("Driver")}
                                  useTranslate={false}
                                  arrayIDTextFormat={driverChooseSelect2Data}
                                  valueID={driverID}
                                  onSelectValueID={(valueID) => {
                                    setDriverID(valueID);
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row className="d-flex align-items-center">
                              <Col md={2}>
                                <Label className="form-control-label">
                                  {t("Date and Time")}
                                </Label>
                              </Col>


                              <Col md={10}>
                                <InputDateTimeGroupForm
                                  label=""
                                  labelFor="proposedDateTime"
                                  inputName="proposedDateTime"
                                  inputId="proposedDateTime"
                                  inputPlaceholder={t("Date and Time")}
                                  dateFormat="yyyy-MM-DD"
                                  value={proposedDateTime}
                                  onDateChange={(date) => {
                                    setproposedDateTime(date);
                                  }}
                                  defaultDate={proposedDateTime}
                                  showTimeBoo={true}
                                  showCustomDateTimeButton={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col>
                            <DeliveryAddressSelectInput
                              additionalAddressDataArray={additionalAddressDataArray}
                              preSelectAddressID={selectedAddressID}
                              returnSelectedAddressID={(deliveryAddressID) => {
                                setSelectedAddressID(deliveryAddressID);
                              }}
                              returnSelectedAddressData={(deliveryAddressData) => {
                                updateSelectedAddressData(deliveryAddressData);
                              }}
                              showLabel={false}
                            />


                          </Col>
                        </Row>
                        <Row className="mb-1"
                          onClick={() => {
                            setModalAddressRelatedDeliveryRecordIsOpen(true);
                          }}>
                          <Col>
                            <DeliveryAddressBadge
                              deliveryAddressData={selectedAddressData}
                              badgeType="address"
                              badgeClassName="statusBadge"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-1"
                          onClick={() => {
                            setModalAddressRelatedDeliveryRecordIsOpen(true);
                          }}>

                          <Col>
                            <DeliveryAddressBadge
                              deliveryAddressData={selectedAddressData}
                              badgeType="relatedDeliveryRecordCount"
                              badgeClassName="statusBadge"
                            />
                            <DeliveryAddressBadge
                              deliveryAddressData={selectedAddressData}
                              badgeType="relatedDeliveryRecordMostUpdatedProposedDateTime"
                              badgeClassName="statusBadge"
                            />

                          </Col>
                        </Row>
                        <Row className="">
                          <Col>
                            <Button
                              color={"success"}
                              id="tooltip443412081"
                              onClick={() => {
                                setModalCreateDeliveryAddressIsOpen(true);
                              }}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text">
                                {t("New Address")}
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
            {/* End Delivery Information */}



            <Card>
              <CardBody>
                <Row className="d-flex justify-content-between">
                  <div>
                    <Button
                      color="danger"
                      type="button" // type="button" means the form will not submit
                      onClick={(e) => {
                        e.preventDefault();
                        clearForm();
                      }}
                    >
                      {t("Reset")}
                    </Button>
                  </div>
                  <div>
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      buttonNormalText={"Create"}
                    />
                    <Button
                      color="default"
                      type="button"
                      onClick={() => props.onHide()}
                    >
                      {t("Back")}
                    </Button>
                  </div>


                </Row>
              </CardBody>
            </Card>




          </ModalBody>
        </Form>
        <ModalFooter>


        </ModalFooter>


        <DeliveryRecordAddProductModal
          show={modalAddProduct}
          onHide={() => setModalAddProduct(false)}
          title={t("Add Delivery Items")}
          button={t("Save")}
          invoiceData={invoiceData}
          addToDeliverySubitemsTable={(element) => {
            addItemArrayToTable(new Array(element));
          }}
        />


        <DeliveryRecordEditProductModal
          show={modalEditProduct}
          onHide={() => setModalEditProduct(false)}
          title={t("Edit Product Items")}
          button={t("Update")}
          invoiceData={invoiceData}
          selectedSubitem={selectedSubitem}
          addToDeliverySubitemsTable={(element) => {
            editItemInTable(element);
          }}
        />

        <AddressModalCreate
          show={modalCreateDeliveryAddressIsOpen}
          onHide={(e) => {
            setModalCreateDeliveryAddressIsOpen(false);
          }}
          title={t("Create new address")}
          button={t("Add")}
          refreshTable={() => { }}
          deliveryAddressToClone={{}}
          getNewAddressData={(newAddressData) => {
            setAdditionalAddressDataArray([newAddressData]);
            setSelectedAddressID(newAddressData.deliveryAddressID);
          }}
        />
        <AddressRelatedDeliveryRecord
                show={modalAddressRelatedDeliveryRecordIsOpen}
                onHide={() => setModalAddressRelatedDeliveryRecordIsOpen(false)}
                preSelectAddressID={selectedAddressData.deliveryAddressID}
                canChooseAddress={false}
            />

      </Modal>
    </>
  );
}

export default DeliveryRecordCreateModal;
