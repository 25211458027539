import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Badge,
  Collapse,
  Container
} from "reactstrap";
import CustomerActionModal from "../customer/CustomerActionModal";

import { useTranslation } from "react-i18next";
import DeliveryRecordScreenFilterModal from "../../../views/pages/deliveryRecord/DeliveryRecordScreenFilterModal";

import InvoiceCreateModal from "../invoice/InvoiceCreateModal"

import DeliveryRecordCard from "../../../views/pages/deliveryRecord/DeliveryRecordCard";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";

import DeliveryRecordEditModal from "./DeliveryRecordEditModal";

import { getIntegerDecodeFromString, getRandomBgColor } from "../../../functions/Utility";

import DeliveryRecordSearchBar from "../components/DeliveryRecordSearchBar.js";
import DeliveryRecordCreateModal from "../deliveryRecord/DeliveryRecordCreateModal";
import DeliveryRecordMessageModal from "./DeliveryRecordMessageModal.js";
import moment from "moment";

function DeliveryRecordListView(props) {
  const initDeliveryRecordData = props.initData;
  const { t } = useTranslation();

  const [refreshTable, setRefreshTable] = React.useState(0);

  //const [refreshTable, setRefreshTable] = React.useState(0);
  const [ModalState, setModalState] = React.useState("");

  // For deliveryRecordCard
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [selectedDeliveryRecodData, onSelectedDeliveryRecodData] = React.useState({});
  const [selectedCustomerData, setSelectedCustomerData] = React.useState({});

  // Data Transfer
  const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});
  const [deliveryRecordSelectedDataForClone, setDeliveryRecordSelectedDataForClone] = React.useState({});

  // GUI
  const [showFullCard, setShowFullCard] = React.useState(false);
  const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);
  const [useDriverColor, setUseDriverColor] = React.useState(false);
  const [useCarIDColor, setUseCarIDColor] = React.useState(false);
  const [useDeliveryStatusColor, setUseDeliveryStatusColor] = React.useState(false);
  const [useInvoiceStatusColor, setUseInvoiceStatusColor] = React.useState(false);
  const [useProposedDateTimeRangeColor, setUseProposedDateTimeRangeColor] = React.useState(false);



  // data control
  
  const [currentFilterStartDate, setCurrentFilterStartDate] = React.useState(new Date());
  const [currentFilterEndDate, setCurrentFilterEndDate] = React.useState(new Date());
  const [useFilterStartDate, setUseFilterStartDate] = React.useState(true);
  const [useFilterEndDate, setUseFilterEndDate] = React.useState(true);
  const [dataRangeStartDate, setDataRangeStartDate] = React.useState(new Date());
  const [dataRangeEndDate, setDataRangeEndDate] = React.useState(new Date()); 
  // data
  const [displayDeliveryRecordData, setDisplayDeliveryRecordData] = React.useState({});

  // modal
  const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
  const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
  const [modalDeliveryRecordEditIsOpen, setModalDeliveryRecordEditIsOpen] = React.useState(false);
  const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);
  const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);
  const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);

  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    props.disablePullToRefresh(modalCustomerActionIsOpen || modalInvoiceMessageIsOpen || modalDeliveryRecordEditIsOpen || modalDeliveryRecordAddIsOpen || modalDeliveryRecordMessageIsOpen || modalInvoiceCreateIsOpen);
  }, [modalCustomerActionIsOpen, modalInvoiceMessageIsOpen, modalDeliveryRecordEditIsOpen, modalDeliveryRecordAddIsOpen, modalDeliveryRecordMessageIsOpen, modalInvoiceCreateIsOpen]);

  React.useEffect(() => {
    // do an inital sort
  }, [props.initData]);


  React.useEffect(() => {
    setDataRangeStartDate(props.dataRangeStartDate);
    setDataRangeEndDate(props.dataRangeEndDate);
  }, [props.dataRangeStartDate, props.dataRangeEndDate]);


  React.useEffect(() => {
    if (displayDeliveryRecordData && Object.values(displayDeliveryRecordData).length >= 1) {
      props.returnDeliveryRecordDatatForStat(displayDeliveryRecordData);
    }
  }, [displayDeliveryRecordData]);

  async function onModalToggle() {
    setModalState("");
  }

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  React.useEffect(() => {
    props.refreshTableData(currentFilterStartDate, currentFilterEndDate, useFilterStartDate, useFilterEndDate);
  }, [currentFilterStartDate, currentFilterEndDate, useFilterStartDate, useFilterEndDate]);

  React.useEffect(() => {
    props.forceRefreshTableData();
  }, [refreshTable]);

  const getCardBgColor = (driverID, carID, deliveryStatus, invoiceStatus, proposedDateString) => {
    // This control the card Bg color 
    if (enableCardBgColor) {
      // priority 
      // Driver color first
      // carID color second // Only if these are all true at the same time 
      if (useDriverColor) {
        return getRandomBgColor(getIntegerDecodeFromString(driverID));
      } else if (useCarIDColor) {
        return getRandomBgColor(getIntegerDecodeFromString(carID));
      } else if (useDeliveryStatusColor) {
        return getRandomBgColor(getIntegerDecodeFromString(deliveryStatus));
      } else if (useInvoiceStatusColor) {
        return getRandomBgColor(getIntegerDecodeFromString(invoiceStatus));
      } else if (useProposedDateTimeRangeColor) {
        return getRandomBgColor(getIntegerDecodeFromString(proposedDateString));
      } else {
        return "";
      }
    }
  }

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <DeliveryRecordSearchBar
          initDeliveryRecordData={initDeliveryRecordData}
          dataRangeStartDate={dataRangeStartDate}
          dataRangeEndDate={dataRangeEndDate}
          returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
          changeCardColor={(enableBgColor, useDriverColor, useCarIDColor, useDeliveryStatusColor, useInvoiceStatusColor, useProposedDateTimeRangeColor) => {
            setEnableCardBgColor(enableBgColor);
            setUseDriverColor(useDriverColor);
            setUseCarIDColor(useCarIDColor);
            setUseDeliveryStatusColor(useDeliveryStatusColor);
            setUseInvoiceStatusColor(useInvoiceStatusColor);
            setUseProposedDateTimeRangeColor(useProposedDateTimeRangeColor);
          }}
          returnFilteredSortedDeliveryRecordData={(filteredSortedDeliveryRecordData) => {
            setDisplayDeliveryRecordData(filteredSortedDeliveryRecordData);
          }}
          returnStartDateEndDate={(expectedDataRangeStartDate, expectedDataRangeEndDate, useStartDate, useEndDate) => {
            // cause the initial date to refresh
            setCurrentFilterStartDate(expectedDataRangeStartDate);
            setCurrentFilterEndDate(expectedDataRangeEndDate);
            setUseFilterStartDate(useStartDate);
            setUseFilterEndDate(useEndDate);
            refreshTableData();
          }}
        >
        </DeliveryRecordSearchBar>
        <Row className="mt-4">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              {displayDeliveryRecordData && Object.values(displayDeliveryRecordData).length >= 1 && displayDeliveryRecordData.map((deliveryItem, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  data-id={deliveryItem.deliveryID}
                  key={deliveryItem.deliveryID}
                >
                  <DeliveryRecordCard
                    showFullCard={showFullCard}
                    cardBgColor={getCardBgColor(deliveryItem.driverID, deliveryItem.carID, deliveryItem.deliveryStatus, deliveryItem?.Invoice?.invoiceStatus, moment(deliveryItem.proposedDateTime).format('yyyy-MM-DD').toString())}
                    enableCardBgColor={enableCardBgColor}
                    isSelectedCard={false}
                    deliveryRecordData={deliveryItem}
                    setClickViewInvoiceButton={(invoice) => {
                      setSelectedInvoice(invoice);
                      setModalInvoiceMessageIsOpen(true);
                    }}
                    onClickCard={(deliveryRecord) => {
                      onSelectedDeliveryRecodData(deliveryRecord);
                      setModalDeliveryRecordEditIsOpen(true);
                    }}
                    cloneInvoice={(invoice) => {
                      setInvoiceSelectedDataForInvoiceClone(invoice);
                      setModalInvoiceCreateIsOpen(true);
                    }}
                    customerAction={(customer) => {
                      setSelectedCustomerData(customer);
                      setModalCustomerActionIsOpen(true);
                    }}
                    cloneDeliveryRecord={(deliveryRecord) => {
                      setDeliveryRecordSelectedDataForClone(deliveryRecord);
                      setModalDeliveryRecordAddIsOpen(true);
                    }}
                    showDeliveryMessage={(deliveryRecord) => {
                      onSelectedDeliveryRecodData(deliveryRecord);
                      setModalDeliveryRecordMessageIsOpen(true);
                    }}
                  />


                </Col>
              ))}
              {displayDeliveryRecordData.length === 0 && (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <strong className="text-info h1">
                        {t("No Delivery Record Found!")}
                      </strong>
                      <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <DeliveryRecordScreenFilterModal
        isOpen={ModalState !== ""}
        toggle={onModalToggle}
        func={props.filter}
      />
      <CustomerActionModal
        show={modalCustomerActionIsOpen}
        onHide={() => setModalCustomerActionIsOpen(false)}
        title={t("Choose Action")}
        button={t("Update")}
        customerData={selectedCustomerData}
        refreshTable={refreshTableData}
      />
      <InvoiceMessageModal
        show={modalInvoiceMessageIsOpen}
        onHide={() => setModalInvoiceMessageIsOpen(false)}
        title={t("Invoice Message")}
        refreshTable={() => { }}
        invoiceData={selectedInvoice}
      />
      <DeliveryRecordEditModal
        show={modalDeliveryRecordEditIsOpen}
        deliveryData={selectedDeliveryRecodData}
        onHide={() => {
          refreshTableData();
          setModalDeliveryRecordEditIsOpen(false);
        }}
      />
      <InvoiceCreateModal
        invoiceToClone={invoiceSelectedDataForInvoiceClone}
        show={modalInvoiceCreateIsOpen}
        onHide={(returnCreatedInvoiceID) => {
          setModalInvoiceCreateIsOpen(false);
          refreshTableData();
        }}
        allowEmptySubitemList={true}
      />

      <DeliveryRecordCreateModal
        show={modalDeliveryRecordAddIsOpen}
        invoiceData={deliveryRecordSelectedDataForClone.Invoice}
        deliveryRecordToClone={deliveryRecordSelectedDataForClone}
        onHide={() => {
          refreshTableData();
          setModalDeliveryRecordAddIsOpen(false);
        }}
      />

      <DeliveryRecordMessageModal
        show={modalDeliveryRecordMessageIsOpen}
        onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
        title={t("Delivery Message")}
        refreshTable={() => { }}
        deliveryRecordData={selectedDeliveryRecodData}
      />
    </>
  );
}

export default DeliveryRecordListView;
