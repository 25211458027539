import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDateString } from "functions/Utility";
//props
//invoiceData
//badgeType :string
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
// useTranlate
function InvoiceBadge(props) {

    const { t } = useTranslation();
    const invoice = props.invoiceData;
    const badgeType = props.badgeType;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";


    function textToShow() {
        if (!invoice || Object.values(invoice).length == 0) {
            return "";
        }

        switch (badgeType) {
            case 'invoiceStatus':
                return `${t(invoice.invoiceStatus).toUpperCase()}`;
            case 'deliveryCount':
                return `${(invoice.DeliveryRecords && Array.isArray(invoice.DeliveryRecords) && invoice.DeliveryRecords.length >= 0) ? invoice.DeliveryRecords?.length : 0} ` + t("delivery");
            case 'paymentCount':
                return `${invoice.PaymentInvoiceMatches?.length} ` + t("payment");
            case 'customerName':
                return `${invoice.Customer?.customerName}`;
            case 'invoiceNetPrice':
                return `$${invoice.invoiceNetPrice}`;
            case 'invoiceRecordDateTime':
                return `${getDateString(invoice.invoiceRecordDateTime, props.useTranslate)}`;
            case 'invoicePaidAmount':
                return `${t('Paid')}:$${invoice.paidAmount}`;
            case 'invoiceUnPaidAmount':
                return `${t('Unpaid')}:$${invoice.unPaidAmount}`;


            default:
                return 'N/A';
        }
    };

    function badgeColor() {

        if (!invoice) {
            return "primary";
        }
        switch (badgeType) {
            case 'invoiceStatus':
                return invoice.invoiceStatus ?
                    invoice.invoiceStatus === "Paid"
                        ? "success"
                        : invoice.invoiceStatus === "In Progress"
                            ? "primary"
                            : invoice.invoiceStatus === "Cancel"
                                ? "warning"
                                : "danger"
                    : "default";
            case 'deliveryCount':
                return invoice.DeliveryRecords?.length >= 1
                    ? "info"
                    : "primary";
            case 'paymentCount':
                return invoice.PaymentInvoiceMatches?.length >= 1
                    ? "success"
                    : "warning"
            case 'invoicePaidAmount':
                return "success"
            case 'invoiceUnPaidAmount':
                return "danger"
            default:
                return "primary";
        }
    };

    return (
        <Badge
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
        >
            {textToShow()}
        </Badge>
    );
}

export default InvoiceBadge;
