import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { combineAddress } from "functions/Delivery";
import { getDeliveryRecordListMostUpdatedProposedDateTime } from "functions/Delivery";
import { getDateStringWithoutTime } from "functions/Utility";
//props
//deliveryRecordData
//badgeType :string
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
function DeliveryAddressBadge(props) {
    
    const { deliveryAddressData, badgeType, badgeClassName } = props;


  const { t, i18n } = useTranslation();
    //  const deliveryRecord = props.deliveryRecordData;
    const truckWorkerList = props.deliveryRecordData?.DeliveryRecordTruckWorkerMatches;
    // const badgeType = props.badgeType;
    // const badgeClassName = props.badgeClassName;
    const activeLang = i18n.language;
    const badgeClassNameDefault = "statusBadge";
    const relatedDeliveryRecordList = deliveryAddressData.relatedDeliveryRecordList ? deliveryAddressData.relatedDeliveryRecordList : [];

    function textToShow() {
        switch (badgeType) {
            case 'address':
                return (deliveryAddressData && Object.keys(deliveryAddressData).length >= 1) ? `${combineAddress(deliveryAddressData)}` : "";
            case 'relatedDeliveryRecordCount':
                return (relatedDeliveryRecordList && Object.keys(relatedDeliveryRecordList).length >= 1) ? `${relatedDeliveryRecordList.length}${" "}${t("delivery")}` : "";
            case 'relatedDeliveryRecordMostUpdatedProposedDateTime':
                return (relatedDeliveryRecordList && Object.keys(relatedDeliveryRecordList).length >= 1) ? `${getDateStringWithoutTime(getDeliveryRecordListMostUpdatedProposedDateTime(relatedDeliveryRecordList), (activeLang === "cn"))}` : "";

            default:
                return 'N/A';
        }
    };

    function badgeColor() {
        switch (badgeType) {
            case 'deliveryStatus':
                return deliveryAddressData?.deliveryStatus === "progress"
                    ? "success"
                    : deliveryAddressData?.deliveryStatus === "pending"
                        ? "warning"
                        : deliveryAddressData?.deliveryStatus === "取消"
                            ? "danger"
                            : "primary";
            case 'address':
                return "primary";
            case 'relatedDeliveryRecordCount':
                return "success";
            default:
                return "primary";
        }
    };
    if (badgeType === 'truckWorkerList') {
        return truckWorkerList && truckWorkerList.map((element, index) => {
            //element is the truckWorker
            return (
                <Badge
                    key={index}
                    color={badgeColor()}
                    className={badgeClassName ? badgeClassName : badgeClassNameDefault}
                >
                    {element?.TruckWorker?.SystemUser?.nickName}
                </Badge>
            );
        });
    } else {
        return (
            <Badge
                color={badgeColor()}
                className={badgeClassName ? badgeClassName : badgeClassNameDefault}
            >
                {textToShow()}
            </Badge>
        );
    }


}

export default DeliveryAddressBadge;
