import Notifications from "views/pages/components/Notifications";
import api from "./Api";
import { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import moment from "moment";

//  Layout data handling functions
export const filterDeliveryRecords = (
  deliveryData,
  search,
  startDate,
  endDate,
  statusList,
  billStatusList
) => {
  // //Search by String
  return deliveryData.filter((data) => {
    let matched;
    let proposedDate = new Date(data.proposedDateTime);
    matched =
      //  Date Match
      startDate <= proposedDate &&
      proposedDate <= endDate &&
      //  Status Match
      ((data.deliveryStatus === "pending" && statusList.scheduled) ||
        (data.deliveryStatus === "progress" && statusList.onTheWay) ||
        (data.deliveryStatus === "完成" && statusList.complete));
    if (matched === false) return matched;
    if (
      (data.deliveryStatus && data.deliveryStatus.includes(search)) ||
      (data.deliveryRemark && data.deliveryRemark.includes(search)) ||
      (data.DeliveryAddress.addressDetailUnitFloor &&
        data.DeliveryAddress.addressDetailUnitFloor.includes(search)) ||
      (data.DeliveryAddress.addressDetailBuilding &&
        data.DeliveryAddress.addressDetailBuilding.includes(search)) ||
      (data.DeliveryAddress.addressDetailStreet &&
        data.DeliveryAddress.addressDetailStreet.includes(search)) ||
      (data.DeliveryAddress.addressDetailDistrict &&
        data.DeliveryAddress.addressDetailDistrict.includes(search)) ||
      (data.DeliveryAddress.addressDetailMainDistrict &&
        data.DeliveryAddress.addressDetailMainDistrict.includes(search)) ||
      (data.DeliveryAddress.addressDetailCity &&
        data.DeliveryAddress.addressDetailCity.includes(search)) ||
      (data.DeliveryAddress.addressRemark &&
        data.DeliveryAddress.addressRemark.includes(search)) ||
      (data.invoice.deliveryStatus &&
        data.invoice.deliveryStatus.includes(search)) ||
      (data.invoice.invoiceRemark &&
        data.invoice.invoiceRemark.includes(search)) ||
      (data.invoice.Customer.customerName &&
        data.invoice.Customer.customerName.includes(search)) ||
      (data.invoice.Customer.customerDescription &&
        data.invoice.Customer.customerDescription.includes(search)) ||
      (data.invoice.Customer.customerEmail &&
        data.invoice.Customer.customerEmail.includes(search)) ||
      (data.invoice.Customer.customerAddress &&
        data.invoice.Customer.customerAddress.includes(search)) ||
      (data.invoice.Customer.customerDefaultRole &&
        data.invoice.Customer.customerDefaultRole.includes(search)) ||
      (data.invoice.Customer.CustomerPhones.phoneUserName &&
        data.invoice.Customer.CustomerPhones.phoneUserName.includes(search)) ||
      (data.invoice.Customer.CustomerPhones.phoneNumber &&
        data.invoice.Customer.CustomerPhones.phoneNumber.includes(search))
    ) {
      return true;
    }
    return false;
  });
};



export const filterDeliveryRecordListByDriverIDList = (
  deliveryRecordList,
  driverIDStringList
) => {
  let res = deliveryRecordList.filter((item) => { return filterFuncDriverIDList(item, driverIDStringList) });
  return res;
};

export const filterDeliveryRecordListBTruckWorkerIDList = (
  deliveryRecordList,
  truckWorkerIDStringList
) => {
  let res = deliveryRecordList.filter((item) => { return filterFuncTurckWorkerIDListDeliveryRecord(item, truckWorkerIDStringList) });
  return res;
};


const filterFuncTurckWorkerIDListDeliveryRecord = (deliveryRercordDataItemToFilter, truckWorkerIDStringList) => {
  try {

    // Cross check with truckWorkerIDStringList
    return deliveryRercordDataItemToFilter.DeliveryRecordTruckWorkerMatches.findIndex((item) => { return filterFuncTurckWorkerIDListdeliveryRecordTruckWorkerMatch(item, truckWorkerIDStringList) }) != -1;
  } catch (error) {
    return true;
  }
};

const filterFuncTurckWorkerIDListdeliveryRecordTruckWorkerMatch = (deliveryRecordTruckWorkerMatchItemToFilter, truckWorkerIDStringList) => {
  try {
    // Cross check with driverIDList
    return truckWorkerIDStringList.findIndex((item) => { return (item === deliveryRecordTruckWorkerMatchItemToFilter?.TruckWorker.truckWorkerID) }) != -1;
  } catch (error) {
    return true;
  }
};


const filterFuncDriverIDList = (dataItemToFilter, driverIDStringList) => {
  try {
    // Cross check with driverIDList
    return driverIDStringList.findIndex((item) => { return (item === dataItemToFilter?.driverID) }) != -1;
  } catch (error) {
    return true;
  }
};

export const getDeliveryRecordListMostUpdatedProposedDateTime = (
  deliveryRecordList
) => {
  if (deliveryRecordList && Array.isArray(deliveryRecordList) && deliveryRecordList.length >= 1) {
    let maxDate = Math.max.apply(Math, deliveryRecordList.map(function (item) { return new Date(item.proposedDateTime); }));
    return new Date(maxDate);
  } else {
    return new Date(1990, 6, 15);
  }

};

export const sortDeliveryRecords = (deliveryData, columnName, order) => {
  // if (columnName === '') return deliveryData;
  return deliveryData;

  let sortedData = [...deliveryData]; //  Copy data to another array
  sortedData.sort((a, b) => {
    let compare;
    if (columnName === "proposedDateTime") {
      compare = Date.parse(a[columnName]) > Date.parse(b[columnName]) ? 1 : -1;
    } else if (columnName === "netPrice") {
      compare = parseFloat(a[columnName]) > parseFloat(b[columnName]) ? 1 : -1;
    }
    return compare * order; //  order: 1=ascending, -1=descending
  });
  return sortedData;
};

export const combineAddress = (addAddressObjToCombine) => {
  if (!addAddressObjToCombine) {
    return "";
  }
  try {
    return addAddressObjToCombine?.addressDetailMainDistrict +
      addAddressObjToCombine?.addressDetailDistrict +
      addAddressObjToCombine?.addressDetailStreet +
      addAddressObjToCombine?.addressDetailBuilding +
      addAddressObjToCombine?.addressDetailUnitFloor;
  } catch (e) {
    return "";
  }
};


export const getAllDeliveryRecords = async () => {
  try {
    const response = await api.get("/deliveryRecords");
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getDeliveryRecordsFast = async () => {
  try {
    const response = await api.get("/deliveryRecords/fast");
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getDeliveryRecordsById = async (id) => {
  try {
    const response = await api.get(`/deliveryRecords/${id}`);
    return response.data.data;
  } catch (error) {
    return [];
  }
};

export const getDeliveryRecordsByInvoice = async (id) => {
  try {
    const response = await api.get(`/deliveryRecords/byInvoiceID/${id}`);
    return response.data.data.rows;
  } catch (error) {
    return [];
  }
};

export const getDeliveryRecordsByAddressID = async (deliveryAddressID) => {
  try {
    const response = await api.get(`/deliveryRecords/byDeliveryAddressID/${deliveryAddressID}`);
    return response.data.data.rows;
  } catch (error) {
    return [];
  }
};


export const getDeliveryRecordsByDateRangeAndDeliveryStatus = async (startDate, endDate, deliveryStatus = '') => {
  try {
    let paramClause = '';
    const startDateFormat = moment(startDate).format("yyyy-MM-DD");
    const endDateFormat = moment(endDate).format("yyyy-MM-DD");
    if (deliveryStatus) {
      paramClause = paramClause + 'deliveryStatus' + '=' + deliveryStatus;
    }
    let response = await apiGet({
      url: `deliveryRecords/searchByDateRange/${startDateFormat}/${endDateFormat}?${paramClause}`,
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};


export const splitDeliveryRemark = (remark) => {
  if (!remark) return remark;
  return remark.match(/#([^#]*)#/g) || [remark];
};

//  API related functions
export const updateDeliveryFull = async (data) => {
  const response = await api.put(
    `/deliveryRecords/updateFullDeliveryRecord`,
    data
  );
  return response.data;
};


export const updateDeliveryRecordCarID = async (deliveryID, carID) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    carID: carID
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateCar",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};


export const updateDeliveryRecordDriverID = async (deliveryID, driverID) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    driverID: driverID
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateDriver",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};


export const updateDeliveryRecordDeliveryStatus = async (deliveryID, deliveryStatus) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    deliveryStatus: deliveryStatus
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateDeliveryStatus",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};

export const updateDeliveryRecordDeliveryAddressID = async (deliveryID, deliveryAddressID) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    deliveryAddressID: deliveryAddressID
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateDeliveryAddressID",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};

export const updateDeliveryRemark = async (deliveryID, deliveryRemark) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    deliveryRemark: deliveryRemark
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateDeliveryRemark",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};

export const updateDeliveryRecordTruckWorkers = async (deliveryID, truckWorkerIDs) => {
  if (!truckWorkerIDs || !Array.isArray(truckWorkerIDs) || truckWorkerIDs.length < 0) {
    return null;
  }

  const dataToSubmit = {
    deliveryID: deliveryID,
    truckWorkerIDs: truckWorkerIDs
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateTruckWorkers",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};


export const updateDeliveryRecordProposedDateTime = async (deliveryID, proposedDateTime) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    proposedDateTime: proposedDateTime
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateProposedDateTime",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};

//  API related functions
export const updateDeliveryRecordSubitem = async (deliveryID, deliveryRecordSubitems) => {
  const dataToSubmit = {
    deliveryID: deliveryID,
    deliveryRecordSubitems: deliveryRecordSubitems
  }

  let response = await apiPut({
    url: "/deliveryRecords/updateDeliveryRecordSubitem",
    data: dataToSubmit,
    responseType: "",
  });

  return response.data;
};


export const getAllDrivers = async () => {
  try {
    let response = await api.get("/drivers/getDrivers");
    let userList = response.data;
    localStorage.setItem("driverList", JSON.stringify(userList));
    return userList;
  } catch (error) {
    return [];
  }
};
export const getAllDriversCache = () => {
  //  Get cached list from local storage
  let driverList = localStorage.getItem("driverList");
  if (driverList !== null) return JSON.parse(driverList);
  return [];
};

export const changeDriverID = async (deliveryID, driverID) => {
  const response = await api.put(`/deliveryRecords/${deliveryID}`, {
    driverID: driverID,
  });
  return response.data;
};
export const changeDeliveryCarID = async (deliveryID, carID) => {
  const response = await api.put(`/deliveryRecords/${deliveryID}`, {
    carID: carID,
  });
  return response.data;
};

export const changeDeliveryRemark = async (deliveryID, remarkToChange) => {
  const response = await api.put(`/deliveryRecords/${deliveryID}`, {
    deliveryRemark: remarkToChange,
  });
  return response.data;
};

export const changeDeliveryProposedDatetime = async (
  deliveryID,
  datetimeValue
) => {
  let dateVar = new Date(datetimeValue);
  let stringSubmit =
    dateVar.toLocaleDateString("en-US") +
    " " +
    dateVar.toLocaleTimeString("en-US") +
    " GMT";
  const response = await api.put(`/deliveryRecords/${deliveryID}`, {
    proposedDateTime: stringSubmit,
  });
  return response.data;
};

export const changeDeliveryLocation = async (deliveryID, position) => {
  const response = await api.put(`/deliveryRecords/${deliveryID}`, {
    address_GPS_latitude: position.addressGPSLatitude,
    address_GPS_longtitude: position.addressGPSLongtitude,
  });
  return response.data;
};

export const addDeliverySignature = async (deliveryID, imageBase64) => {
  const response = await api.post(`/deliveryRecords/deliverySignature`, {
    deliveryID: deliveryID,
    imageBase64Content: imageBase64,
  });
  return response.data;
};

export const getDeliverySignature = async (deliveryID) => {
  try {
    const response = await api.get(
      `/deliveryRecords/deliverySignature/${deliveryID}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const deleteDeliverySignature = async (deliveryID) => {
  const response = await api.delete(
    `/deliveryRecords/deliverySignature/${deliveryID}`
  );
  return response.data;
};

//return a photoID
export const addDeliveryPhoto = async (deliveryID, imageBase64, imageRemark = "", imageOrder = "1") => {

  const response = await api.post(`/deliveryRecords/deliveryPhoto`, {
    deliveryID: deliveryID,
    imageRemark: imageRemark,
    imageOrder: imageOrder,
    imageBase64Content: imageBase64,
  });

  return response.data?.data?.photoID; //return a json with photoID
};

//return a photoID
export const updateDeliveryPhotoMetaData = async (deliveryID, imageID, imageRemark = "", imageOrder = "1") => {
  const response = await api.put(`/deliveryRecords/deliveryPhotoMetaData`, {
    deliveryID: deliveryID,
    imageRemark: imageRemark,
    imageOrder: imageOrder,
    imageID: imageID,
    imageType: "deliveryPhoto"
  });
  return response.data?.data?.photoID; //return a json with photoID
};

export const getDeliveryPhoto = async (deliveryID) => {
  try {
    const response = await api.get(
      `/deliveryRecords/deliveryPhoto/${deliveryID}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const deleteDeliveryPhoto = async (imageID, deliveryID) => {
  const response = await api.delete(
    `/deliveryRecords/deliveryPhoto/${imageID}/${deliveryID}`
  );
  return response.data;
};

export const deleteDeliveryRecord = async (deliveryID) => {
  let response = await apiDelete({
    url: `deliveryRecords/deleteDeliveryRecord/${deliveryID}`,
    responseType: "",
  });
  return response.data;
};

//New API
export const getAllDeliveryOrders = async () => {
  try {
    return api.get("/deliveryRecords").then((response) => {
      try {
        if (response.data.code == 200) {
          return response.data.data.rows;
        } else {
          return Notifications({ type: "error", message: response.data.message });
        }
      } catch (error) {
        return Notifications({ type: "error" });
      }
    });
  } catch (error) {
    return [];
  }

};

export const getRemainingDeliverySubitemsByInvoiceID = async (invoiceID) => {
  try {
    let response = await apiGet({ url: `deliveryRecords/remainingDeliverySubitemsByInvoiceID/${invoiceID}`, responseType: "" });
    return response.status == 200 ? response.data.data : [];
  } catch (error) {
    return [];
  }
};


export const storeDeliveryRecord = async (data) => {
  let response = await apiPost({
    url: "deliveryRecords/addDeliveryRecord",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data.data.createDeliveryRecordData.deliveryID    //Just return the invoiceID directly
    : response.status;
};

export const storeMultipleDeliveryRecord = async (data) => {
  return api
    .post("deliveryRecords/addMultipleDeliveryRecord", data)
    .then((response) => {
      try {
        if (response.data.code == 200) {
          return Notifications({
            type: "store",
            message: response.data.message,
          });
        } else {
          return Notifications({
            type: "error",
            message: response.data.message,
          });
        }
      } catch (error) {
        return Notifications({ type: "error" });
      }
    });
};


export const getDeliveryStatusArray = () => {
  return [
    // { id: "排程中", text: "排程中" },
    //  { id: "送緊貨", text: "送緊貨" },
    // { id: "完成", text: "完成" },
    // { id: "取消", text: "取消" },
    { id: "pending", text: "pending", color: "danger" },
    { id: "progress", text: "progress", color: "default" },
    { id: "finish", text: "finish", color: "success" },
    { id: "cancel", text: "cancel", color: "warning" },
  ];
};

export const getDefaultDeliveryStatus = () => {
  return "pending";
};

export const getDeliveryStatusColor = (deliveryStatusToCheck) => {
  const deliveryStatusArray = getDeliveryStatusArray();
  try {
    if (deliveryStatusArray) {
      let result = deliveryStatusArray.filter((item) => {
        return item.id.toLowerCase() == deliveryStatusToCheck.toLowerCase()
      });
      if (result && result.length == 1) {
        return result[0].color;
      } else {
        return "default";
      }
    }
  } catch (error) {
    return "default";
  }
};
