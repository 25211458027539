import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Col,
  Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeText from "../components/InputTypeText.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm.js";
import {
  getGoodSelect2,
} from "../../../functions/Goods";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";

import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";
import GoodInventoryBadge from "../components/GoodInventoryBadge.js";
import GoodInventoryModalEdit from "../good/GoodInventoryModalEdit.js";

function InvoiceAddProductModal(props) {
  const { t } = useTranslation();


  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshCounter, setRefreshCounter] = React.useState(0);

  //Initialize Data
  // in a structure of [{id: xxx, text: yyy, obj: {....}}, {.......}]
  const [goodChooseList, setGoodChooseList] = React.useState([]);
  const [subItemPrice, setSubItemPrice] = React.useState(0);

  //Post Data
  const [newSubitem, setNewSubitem] = React.useState({
    goodSelected: {},
    quantity: 0,
    unitPrice: 0,
    invoiceSubitemRemark: "",
  });

  const { goodSelected, quantity, unitPrice, invoiceSubitemRemark } = newSubitem;


  //Other Display data
  const [selectedGoodInventoryList, setSelectedGoodInventoryList] = React.useState([]);

  const [selectedGoodInventoryData, setSelectedGoodInventoryData] = React.useState({});
  const [selectedStoreData, setSelectedStoreData] = React.useState({});

  // Modal 
  const [modalEditInventoryIsOpen, setModalEditInventoryIsOpen] = React.useState(false);


  //Initial Get Good Data List
  React.useEffect(() => {
    const initDataGood = async () => {
      let getGoodList = await getGoodSelect2();
      return setGoodChooseList(getGoodList);
    };
    if (props.show) {
      initDataGood();
    }
  }, [props.show, refreshCounter]);


  React.useEffect(() => {
    if (goodChooseList && Array.isArray(goodChooseList) && goodChooseList.length >= 1) {
      if (goodSelected && Object.values(goodSelected).length >= 1) {
        // just to re-select the good
        onChooseGood(goodSelected.goodID, false, true);
      }
    }
  }, [goodChooseList]);


  React.useEffect(() => {
    setSubItemPrice(quantity * unitPrice);
  }, [quantity, unitPrice]);




  const onInputChange = (e) => {
    //  if (e.target.name == "" && e.target.name == "goodID") {
    if (e.target.name == "") {
    } else {
      setNewSubitem({ ...newSubitem, [e.target.name]: e.target.value });
    }
  };

  const onChangeUnitPrice = (unitPriceToChange) => {
    if (isNaN(parseFloat(unitPriceToChange))) {
      // To improve user experience
      // Allow a empty string
      setNewSubitem({ ...newSubitem, unitPrice: '' });
    } else {
      setNewSubitem({ ...newSubitem, unitPrice: unitPriceToChange });
    }
  };

  const onChangeQuantity = (quantityToChange) => {
    if (isNaN(parseFloat(quantityToChange))) {
      // To improve user experience
      // Allow a empty string
      setNewSubitem({ ...newSubitem, quantity: '' });
    } else {
      setNewSubitem({ ...newSubitem, quantity: parseFloat(quantityToChange) });
    }
  };

  const onChooseGood = async (goodID, changePriceQuantity = true , changeInventory = true) => {
    if (goodID === "") {

    } else {
      let goodFromServer = (getGoodFromLocalList(goodID)) || {};

      if (goodFromServer) {
        if (changePriceQuantity) {
          setNewSubitem({ ...newSubitem, goodSelected: goodFromServer, unitPrice: parseFloat(goodFromServer.goodDefaultUnitPrice), quantity: parseFloat(goodFromServer.goodDefaultUnitQuantity) });
        }
        if (changeInventory) {
          onSetGoodInventoryList(goodFromServer.GoodInventories);
        }
      }
    }
  };


  const onSetGoodInventoryList = (goodInventoryList) => {
    if (goodInventoryList && Array.isArray(goodInventoryList) && goodInventoryList.length >= 0) {
      setSelectedGoodInventoryList(goodInventoryList);
    } else {
      setSelectedGoodInventoryList([]);
    }
  }

  const getGoodFromLocalList = (goodID) => {

    if (!goodChooseList || Object.values(goodChooseList).length <= 0) {
      return null;
    }
    let goodArrayToGet = goodChooseList.find((item) => item.id === goodID);

    if (!goodArrayToGet || Object.values(goodArrayToGet).length <= 0) {
      return null;
    }
    return goodArrayToGet.obj;
  }

  const addItems = (e) => {
    if (!goodSelected || Object.keys(goodSelected).length <= 0) {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a good")
      });
      return;
    }


    // Data check 
    if (isNaN(parseFloat(quantity))) {
      Notifications({
        type: "dataCheck",
        message: t("Please input a quantity")
      });
      return;
    }

    if (isNaN(parseFloat(unitPrice))) {
      Notifications({
        type: "dataCheck",
        message: t("Please input a unitPrice")
      });
      return;
    }

    props.addInvoicesSubitems(
      parseFloat(quantity),
      parseFloat(unitPrice),
      invoiceSubitemRemark,
      goodSelected
    );

    setNewSubitem({
      goodID: "",
      quantity: 0,
      unitPrice: 0,
      invoiceSubitemRemark: "",
    });

    clearForm();
    props.onHide();
  };




  const clearForm = () => {
    setSubItemPrice(0);

    setNewSubitem({
      good: {},
      quantity: 0,
      unitPrice: 0,
      invoiceSubitemRemark: "",
    });

    setSelectedGoodInventoryList([]);

    setSelectedGoodInventoryData({});
    setSelectedStoreData({});  
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader >{props.title}</ModalHeader>
        <ModalBody className="mt--4">
          <Col md={12} lg={12}>
            <Form>
              <InputTypeSelect2Form
                data={goodChooseList}
                placeholder={t("--Select Item--")}
                value={goodSelected?.goodID}
                nameSelect2="goodID"
                idSelect2="goodID"
                label={t("Item Name")}
                handleOnChangeSelect={(e) => onChooseGood(e.target.value, true, true)}
              />

              <div className="mt-2 d-flex">
                {(selectedGoodInventoryList && Array.isArray(selectedGoodInventoryList) && selectedGoodInventoryList.length >= 1) &&
                  selectedGoodInventoryList.map((item, index) => {
                    return (
                      <GoodInventoryBadge
                        key={index}
                        goodInventoryData={item}
                        goodData={goodSelected}
                        badgeType=""
                        badgeClassName="statusBadge"
                        onClickBadge={(goodInventoryData, goodData) => {
                          setSelectedGoodInventoryData(goodInventoryData);
                          setSelectedStoreData(goodInventoryData.Store);
                          // setSelectedGoodData(goodData);
                          setModalEditInventoryIsOpen(true);
                        }}
                      />
                    )
                  })
                }
              </div>

              <InputTypeGroupTextForm
                type="number"
                append={(goodSelected && Object.keys(goodSelected).length > 0) ? true : false}
                appendIcon={false}
                appendIconClass=""
                appendText={
                  (goodSelected && Object.keys(goodSelected).length > 0)
                    ? "/" + goodSelected.goodUnit
                    : ""
                }
                label={t("Price")}
                labelFor="unitPrice"
                inputName="unitPrice"
                inputId="unitPrice"
                inputPlaceholder={t("Price")}
                value={unitPrice}
                handleChange={(e) => onChangeUnitPrice(e.target.value)}
              // readOnly={false}
              />
              <InputTypeTextForm
                type="number"
                label={t("Quantity")}
                labelFor="quantity"
                inputName="quantity"
                inputId="quantity"
                inputPlaceholder={t("Quantity")}
                value={quantity}
                handleChange={(e) => onChangeQuantity(e.target.value)}
              />
              <InputTypeTextForm
                type="number"
                label={t("subtotal")}
                labelFor="subItemPrice"
                inputName="subItemPrice"
                inputId="subItemPrice"
                inputPlaceholder={t("subtotal")}
                value={subItemPrice}
                readOnly={true}
              //   handleChange={(e) => onInputChange(e)}
              />
              <InputTypeTextForm
                label={t("Invoice Item Remark")}
                labelFor="invoiceSubitemRemark"
                inputName="invoiceSubitemRemark"
                inputId="invoiceSubitemRemark"
                inputPlaceholder={t("Invoice Item Remark")}
                value={invoiceSubitemRemark}
                handleChange={(e) => onInputChange(e)}
              />
            </Form>
          </Col>
        </ModalBody>
        <ModalFooter>

          <Row className="w-100 d-flex justify-content-between">
            <div>
              <Button
                color="danger"
                type="button" // type="button" means the form will not submit
                onClick={(e) => {
                  e.preventDefault();
                  clearForm();
                }}
              >
                {t("Reset")}
              </Button>
            </div>
            <div>
              <Button color="primary" onClick={addItems} type="button">
                {props.button}
              </Button>
              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>

          </Row>

        </ModalFooter>
      </Modal>

      <GoodInventoryModalEdit
        show={modalEditInventoryIsOpen}
        onHide={() => setModalEditInventoryIsOpen(false)}
        title={t("Edit good inventory")}
        button={t("Update")}
        goodData={goodSelected}
        goodInventoryData={selectedGoodInventoryData}
        storeData={selectedStoreData}
        refreshTable={() => { setRefreshCounter(refreshCounter + 1) }}
      />
    </>
  );
}

export default InvoiceAddProductModal;
