/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from "react-device-detect";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
} from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import { isAccessible } from "functions/SystemUserAccess";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";

function Sidebar({ toggleSidenav, sidenavOpen, appPageGroupList, logo, rtlActive, setIsSideBarAlwaysOpen }) {
  const [state, setState] = React.useState({});
  const location = useLocation();
  const { t } = useTranslation();

  const [showPermanantFixSideBarButton, setShowPermanantFixSideBarButton] = React.useState(window.innerWidth > 1200 && !isMobile);
  const [permanantFixSideBarBoo, setPermanantFixSideBarBoo] = React.useState(false);




  React.useEffect(() => {
    setState(getCollapseStates(appPageGroupList));
    // eslint-disable-next-line
  }, []);


  React.useEffect(() => {
    setIsSideBarAlwaysOpen(permanantFixSideBarBoo);
  }, [permanantFixSideBarBoo]);


  React.useEffect(() => {
    if (showPermanantFixSideBarButton) {
      // do nothing
    } else {
      // Also unlock the permanant fix
      setPermanantFixSideBarBoo(false);
    }
  }, [showPermanantFixSideBarButton]);

  const changingWindowInnerWidth = () => {
    if (window.innerWidth > 1200 && !isMobile) {
      setShowPermanantFixSideBarButton(true );
    } else {
      setShowPermanantFixSideBarButton(false);
    }
  };

  // Added an event handler to monitor the inner width changing
  window.onresize = changingWindowInnerWidth;

  
  const isAnyPropViewAccessible = (propViewsArray) => {

    if (!propViewsArray || !Array.isArray(propViewsArray)) return false;
    return propViewsArray.some((item) => isAccessible(item.accessID));
  };



  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };


  // makes the sidenav normal on hover (actually when mouse enters on it)

  // important note
  // g-sidenav-show tells the nav item show all the full text
  // g-sidenav-pinned tells the sidebar to show

  // So, the hover in/out is only suitable for xl-screen
  // Not suitable for mobile device
  // Mobile device no hover
  const onMouseEnterSidenav = () => {

    if (isMobile) {
      return;
    }

    if (window.innerWidth > 1200) {
      if (!document.body.classList.contains("g-sidenav-pinned") || permanantFixSideBarBoo) {
          document.body.classList.add("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-show");
        document.body.classList.remove("g-sidenav-hidden");
        // show the button
        setShowPermanantFixSideBarButton(true);
      }
    }


  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {

    if (isMobile) {
      return;
    }
    if (window.innerWidth > 1200) {
      if (document.body.classList.contains("g-sidenav-pinned") && !permanantFixSideBarBoo) {
        //     document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-show");
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
        // hide the button
        setShowPermanantFixSideBarButton(false);
      }
    }


  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    if (!routes || !Array.isArray(routes)) {
      return {};
    }
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) { // This condition means the current path is the same as the route path
        return true;
      }
    }
    return false;
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {

    if (window.innerWidth < 1200) { // that means a xl screen // do not close the side bar
      toggleSidenav();
    }
  };



  const changePermanentFixSideBarMode = () => {
    setPermanantFixSideBarBoo(!permanantFixSideBarBoo);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createNavLinksFromGroupList = (AppPageGroupObj) => {
    if (!AppPageGroupObj || !Array.isArray(AppPageGroupObj)) {
      return [];
    }
    return AppPageGroupObj.map((prop, index) => {
      if (prop.redirect) {
        return null;
      }

      if (prop.collapse) { // prop.collapse means it is the group 
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          (isAnyPropViewAccessible(prop.views) &&
            <NavItem key={index + 100 + Math.random().toString()}>
              <NavLink
                data-toggle="collapse"
                aria-expanded={state[prop.state]}
                // className={classnames([{
                //   active: getCollapseInitialState(prop.views),
                // }
                // ])}
                className={getCollapseInitialState(prop.views) ? 'selectedSideBarItem' : 'sideBarItem'}
                onClick={(e) => {
                  e.preventDefault();
                  setState(st);
                }}
              >
                {prop.icon ? (
                  <>
                    <i className={prop.icon} />
                    <span className="nav-link-text">{t(prop.name)}</span>
                  </>
                ) : prop.miniName ? (
                  <>
                    <span className="sidenav-mini-icon"> {t(prop.miniName)} </span>
                    <span className="sidenav-normal"> {t(prop.name)} </span>
                  </>
                ) : null}
              </NavLink>
              <Collapse isOpen={state[prop.state]} dimension="width" appear={true}>
                <Nav className="nav-sm flex-column">
                  {createNavLinksFromGroupList(prop.views)}
                </Nav>
              </Collapse>
            </NavItem>


          )

        );
      } else {
        return (
          isAccessible(prop.accessID) && // This function controls the side bar item showing or not
          <NavItem className={activeRoute(prop.path)} key={index + Math.random().toString()}>
            <NavLink
              className={`pl-5 pr-4 py-2 ${activeRoute(prop.path) === 'active' ? 'selectedSideBarItem' : "sideBarItem"}`}
              to={prop.path}
              activeClassName=""
              onClick={(e) => {
                closeSidenav();
              }}
              tag={NavLinkRRD}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{t(prop.name)}{getAccessibleTagForSafeMode(prop.accessID)}</span>
                </>

              ) : prop.miniName !== undefined ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal">{prop.name} </span>
                </>
              ) : (
                prop.name
              )}
            </NavLink>
          </NavItem >

        );

      }
    });
  };


  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }






  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        <div className="flex-grow-1">

          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                // className="sidebarLogo"
                src={logo.imgSrc}
              />
            </NavbarBrand>
            // <div className="nav-link">
            //   <img
            //     alt={logo.imgAlt}
            //     className="sidebarLogo"
            //     src={logo.imgSrc}
            //   />
            // </div>
          ) : null}
        </div>
        <div >

          <NavbarBrand {...navbarBrandProps}>

            <div
              // className={classnames("sidenav-toggler d-none d-xl-block", "d-flex justify-content-end", {
              className={classnames("sidenav-toggler d-xl-block", {
                active: sidenavOpen,
              })}
            >

              <div className=" sidenav-toggler-inner "
                onClick={(e) => {
                  toggleSidenav(e);
                }}>
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>




            </div>

          </NavbarBrand>
        </div>
      </div>
      {showPermanantFixSideBarButton &&
        <div className="ml-2" >
          <Button
            className={permanantFixSideBarBoo ? "btn-success" : "btn-warning"}
            color=""
            id="tooltip443453534"
            onClick={(e) => {
              changePermanentFixSideBarMode()
            }}
            size="sm"
          >
            <span className="btn-inner--icon mr-1">
              <i className="ni ni-pin-3" />
            </span>
            <span className="btn-inner--text">
              {permanantFixSideBarBoo ? t("Fixed") : t("Floating")}
            </span>
          </Button>
        </div>
      }
      <div >
        {process.env.REACT_APP_ENV !== "Production"
          ? process.env.REACT_APP_API_BASE_URL_DEVELOPMENT
          : ""}
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createNavLinksFromGroupList(appPageGroupList)}</Nav>
        </Collapse>
      </div>
    </div>
  );

  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"
        //  (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
